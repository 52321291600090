import React, { useContext, useEffect, useState } from 'react';
import { styled } from 'shared/components/ihcl/styled';

import {
  OnboardingContext,
  OnboardingWrapper,
} from 'registration/containers/Onboarding';

import { trackComponentView } from 'shared/helpers/tracking';
import { doPost } from 'shared/helpers/http';
import { OnboardingData } from 'registration/helpers/types';

import { Stack } from 'shared/components/ihcl/patterns';
import colorsBackground from 'images/colors-bg.webp';
import thoughtBubbles from 'images/thought-bubbles.webp';
import { useReserveNextScreening } from 'api/talent/hooks/screenings';
import { Text } from 'shared/components/ihcl/text';
import LoadingDots from 'shared/components/animations/LoadingDots';

const sendUpdate = ({ talentProfileId, nextAction }) => {
  const updates = {
    talent_tags: ['onboard_schedule_screen_sept2021'],
  };
  doPost(`/talent_profile/${talentProfileId}/tag`, updates)
    .catch((error) => {
      console.error(error);
    })
    .finally(() => {
      window.location.assign(new URL(nextAction, window.location.origin));
    });
};

const Background = styled('div', ({ $theme }) => ({
  backgroundImage: `url(${colorsBackground})`,
  backgroundSize: '100% 100%',
  filter: `blur(${$theme.sizing.unit40})`,
  opacity: '30%',
  position: 'absolute',
  width: '100%',
  height: '100lvh',
  left: '0',
  top: '0',
}));

const Content = styled('div', ({ $theme }) => ({
  display: 'flex',
  gap: $theme.sizing.unit48,
  position: 'relative',
  textAlign: 'center',
  alignItems: 'center',
  height: '100lvh',
}));

const ThoughtBubbles = styled('img', {
  width: '100%',
});

type DefaultScheduledScreeningProps = {
  nextAction: string;
};

export const DefaultScheduledScreening = ({
  nextAction,
}: DefaultScheduledScreeningProps) => {
  const { onboardingData: talentProfile } = useContext<{
    onboardingBasePath: string;
    onboardingData: OnboardingData;
    setOnboardingData: Function;
  }>(OnboardingContext);
  const [timerElapsed, setTimerElapsed] = useState(!!talentProfile?.screening);

  const { mutate: reserveNextScreening, isLoading: isReserving } =
    useReserveNextScreening();

  useEffect(() => {
    trackComponentView('talent.screening.default.transition');
    reserveNextScreening();
    const timer = setTimeout(() => {
      setTimerElapsed(true);
    }, 3000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (!isReserving && timerElapsed) {
      sendUpdate({ talentProfileId: talentProfile.id, nextAction });
    }
  }, [isReserving, timerElapsed]);

  return (
    <>
      <OnboardingWrapper $compactMaxWidth>
        <Background />
        <Content>
          <Stack
            $flexDirection="column"
            $gapSizing="unit48"
            $alignItems="center"
          >
            <ThoughtBubbles alt="thought-bubbles" src={thoughtBubbles} />
            <Text variant="HeadingSmall">
              Let&apos;s find a time to talk — because you&apos;re more than
              your resume.
            </Text>
            <LoadingDots color="accent30" />
          </Stack>
        </Content>
      </OnboardingWrapper>
    </>
  );
};

export default DefaultScheduledScreening;
