import React, { useEffect, useState } from 'react';
import range from 'just-range';
import { styled } from 'shared/components/ihcl/styled';
import { Select } from './select';

export const DEFAULT_MAX_YEAR = new Date().getFullYear();
export const DEFAULT_MIN_YEAR = (Math.floor(DEFAULT_MAX_YEAR / 100) - 1) * 100;

const MAX_MONTH = 12;
const MIN_MONTH = 1;

const MonthWrapper = styled('div', {
  flexBasis: '100%',
  flexGrow: 1,
  position: 'relative',
});

const Wrapper = styled('div', ({ $theme }) => ({
  display: 'flex',
  gap: $theme.sizing.unit16,
}));

const YearWrapper = styled('div', {
  flexBasis: '200%',
  flexGrow: 1,
  position: 'relative',
});

type MonthPickerProps = {
  yearLabel?: string;
  monthLabel?: string;
  maxYear?: string;
  minYear?: string;
  onChange: Function;
  size?: string;
  yearValue?: string;
  monthValue?: string;
  error?: string;
  disabled?: Boolean;
  caption?: string;
  instanceId?: string;
};

export const MonthPicker: React.FC<MonthPickerProps> = ({
  yearLabel = 'Year',
  monthLabel = 'Month',
  maxYear = DEFAULT_MAX_YEAR.toString(),
  minYear = DEFAULT_MIN_YEAR.toString(),
  onChange,
  size = 'default',
  yearValue = null,
  monthValue = null,
  error = null,
  disabled = false,
  caption = null,
  instanceId = 'SplitMonthPicker',
}) => {
  const [year, setYear] = useState(yearValue);
  const [month, setMonth] = useState(monthValue);
  useEffect(() => {
    onChange({ year, month });
  }, [year, month]);

  const monthOptions = range(MIN_MONTH, MAX_MONTH + 1).map((m) => ({
    label: m.toString().padStart(2, '0'),
    id: m.toString().padStart(2, '0'),
  }));

  const yearOptions = range(parseInt(minYear, 10), parseInt(maxYear, 10) + 1)
    .map((y) => ({
      label: y.toString(),
      id: y.toString(),
    }))
    .reverse();

  return (
    <Wrapper>
      <YearWrapper>
        <Select
          instanceId={`${instanceId}-year`}
          label={yearLabel}
          max={maxYear}
          min={minYear}
          onChange={({ option }) => setYear(option?.id)}
          options={yearOptions}
          size={size}
          value={yearOptions.filter((option) => option.id === year)}
          error={error}
          disabled={disabled}
          caption={caption}
        />
      </YearWrapper>
      <MonthWrapper>
        <Select
          instanceId={`${instanceId}-month`}
          label={monthLabel}
          max={MAX_MONTH}
          min={MIN_MONTH}
          onChange={({ option }) => setMonth(option?.id)}
          options={monthOptions}
          size={size}
          value={monthOptions.filter((option) => option.id === month)}
          error={error}
          disabled={disabled}
          caption={caption}
        />
      </MonthWrapper>
    </Wrapper>
  );
};
