import { createAction } from 'redux-actions';

import { doPost, doPut } from 'shared/helpers/http';
import { displayErrorDialog } from './errorActions';

export const scheduleScreeningStarted = createAction(
  'SCHEDULE_SCREENING_STARTED'
);

export const scheduleScreeningComplete = createAction(
  'SCHEDULE_SCREENING_COMPLETE'
);

export const scheduleScreeningError = createAction('SCHEDULE_SCREENING_ERROR');

export const cancelScreeningStarted = createAction('CANCEL_SCREENING_STARTED');
export const cancelScreeningComplete = createAction(
  'CANCEL_SCREENING_COMPLETE'
);
export const cancelScreeningError = createAction('CANCEL_SCREENING_ERROR');

export function scheduleScreening(talentProfileId) {
  return (dispatch) => {
    dispatch(scheduleScreeningStarted());

    return doPost(`/nurse_profile/${talentProfileId}/schedule_screening`, {})
      .then((json) => {
        dispatch(scheduleScreeningComplete(json));
      })
      .catch((error) => {
        console.error(error);
        dispatch(scheduleScreeningError());

        const errMsg =
          'We encountered an error while confirming your intro call. Please ' +
          'check your internet connection, refresh your browser, and try again.';
        dispatch(displayErrorDialog(errMsg));
      });
  };
}

export function cancelScreening(screening_id) {
  return async (dispatch) => {
    dispatch(cancelScreeningStarted());

    doPut(`/appointment_window_screenings/${screening_id}/cancel`)
      .then((json) => dispatch(cancelScreeningComplete(json)))
      .catch((error) => {
        console.error(error);
        dispatch(cancelScreeningError());

        const errMsg =
          'We encountered an error while canceling your intro call. Please ' +
          'check your internet connection, refresh your browser, and try again.';
        dispatch(displayErrorDialog(errMsg));
      });
  };
}
