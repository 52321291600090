import { ParsedQs } from 'qs';

export const DELIMITER = '|';

export enum UnbounceParam {
  // '|'-delimited list of city, state locations, eg
  // 'New York, NY|Jersey City, NJ'
  desired_location = 'desired_location',
}

enum ExperimentVariant {
  yuki = 'yuki',
  zebra = 'zebra',
}

export const saveUnbounceParamToLocalforage = async (
  paramName: UnbounceParam,
  params: ParsedQs
) => {
  const value = params[paramName];
  if (value) {
    await window.localforage?.setItem(paramName, value);
  }
};

export const getUnbounceParamFromLocalforage = (paramName: UnbounceParam) =>
  window.localforage?.getItem(paramName);

export const getShouldPreselectLocations = async (
  variant: ExperimentVariant
) => {
  const savedLocations = await getUnbounceParamFromLocalforage(
    UnbounceParam.desired_location
  );

  return (
    [ExperimentVariant.yuki, ExperimentVariant.zebra].includes(variant) &&
    !!savedLocations
  );
};
