import React from 'react';

// eslint-disable-next-line react/prop-types
const RedirectPage = ({ intent }) => {
  React.useEffect(() => {
    let redirectUrl;
    switch (intent) {
      case 'advice':
        redirectUrl = '/talent/advice';
        break;
      case 'education':
        redirectUrl = '/talent/continuing_education';
        break;
      case 'mental_health':
        redirectUrl = '/talent/mental_health';
        break;
      case 'network':
        redirectUrl = '/talent/community/forum';
        break;
      case 'resume':
        redirectUrl = '/nurse_profile/resume_generator/work-experience';
        break;
      case 'salary':
        redirectUrl = '/talent/salary_calculator';
        break;
      default:
        redirectUrl = '/talent/home';
    }

    window.location.replace(new URL(redirectUrl, window.location.origin));
  }, [intent]);

  return null;
};

export default RedirectPage;
