import { useMutation } from '@tanstack/react-query';
import { confirmScreening, reserveNextScreening } from '../screenings';

export const useReserveNextScreening = () =>
  useMutation({
    mutationFn: () => reserveNextScreening(),
  });

export const useConfirmScreening = ({ id, onSuccess }) =>
  useMutation({
    mutationFn: () => confirmScreening(id),
    onSuccess,
  });
