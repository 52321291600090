import React, { useContext } from 'react';
import {
  OnboardingContext,
  OnboardingSubtitle,
} from 'registration/containers/Onboarding';
import {
  MoneyBagIcon,
  PerformanceUserGraphIcon,
} from 'shared/components/ihcl/icon';
import { styled } from 'shared/components/ihcl/styled';

const ToutWrapper = styled('div', {
  textAlign: 'left',
  paddingRight: '56px',
  paddingLeft: '56px',
  marginBottom: '32px',
});

const ToutRow = styled('div', {
  display: 'flex',
  alignItems: 'center',
  minHeight: '32px',
  fontSize: '14px',
  marginBottom: '16px',
});

const IconCircle = styled('div', ({ $theme }) => ({
  float: 'left',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: $theme.colors.primary,
  color: $theme.colors.white,
  height: '32px',
  width: '32px',
  minWidth: '32px',
  borderRadius: '32px',
  marginRight: '12px',
}));

export const OtherLocationsTout = () => {
  // @ts-ignore
  const { profileType = 'talent' } = useContext(OnboardingContext);
  if (profileType === 'tech') {
    return (
      <>
        <OnboardingSubtitle>
          <strong>
            Are you open to opportunities in other cities or states?
          </strong>
        </OnboardingSubtitle>
        <OnboardingSubtitle>
          We are live in California and will expand in the near
          future—don&apos;t miss out!
        </OnboardingSubtitle>
        <ToutWrapper>
          <ToutRow>
            <IconCircle>
              <MoneyBagIcon height="18" width="18" />
            </IconCircle>
            <div>
              <strong>Earn more</strong> in annual salary!
            </div>
          </ToutRow>
          <ToutRow>
            <IconCircle>
              <PerformanceUserGraphIcon height="18" width="18" />
            </IconCircle>
            <div>
              <strong>More jobs</strong> in your specialties!
            </div>
          </ToutRow>
        </ToutWrapper>
      </>
    );
  }
  return (
    <>
      <OnboardingSubtitle>
        <strong>
          Are you interested in working in other cities or states?
        </strong>
      </OnboardingSubtitle>
      <ToutWrapper>
        <ToutRow>
          <IconCircle>
            <MoneyBagIcon height="18" width="18" />
          </IconCircle>
          <div>
            <strong>Earn more</strong> in annual salary!
          </div>
        </ToutRow>
        <ToutRow>
          <IconCircle>
            <PerformanceUserGraphIcon height="18" width="18" />
          </IconCircle>
          <div>
            <strong>More jobs</strong> in your specialties!
          </div>
        </ToutRow>
      </ToutWrapper>
    </>
  );
};
