import { handleActions } from 'redux-actions';
import lodash from 'lodash';

import { updateTechProfileComplete } from 'shared/actions/techProfileActions';

import { cacheTechProfileChanges } from '../actions/techProfileActions';

const techProfileReducer = handleActions(
  {
    [updateTechProfileComplete]: {
      next(state, action) {
        return action.payload;
      },
      throw(state) {
        return state;
      },
    },

    [cacheTechProfileChanges]: (state, action) => {
      const { payload } = action;
      let nextState = {};
      // TODO: Change to just-pick
      const profileChanges = lodash.pick(payload, [
        'first_name',
        'last_name',
        'phone',
      ]);

      if (payload.user_attributes && payload.user_attributes.email) {
        profileChanges.email = payload.user_attributes.email;
      }

      if (payload.first_name && payload.last_name) {
        profileChanges.full_name = [payload.first_name, payload.last_name].join(
          ' '
        );
      }

      nextState = { ...state, ...profileChanges };

      return nextState;
    },
  },
  {}
);

export default techProfileReducer;
