import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { styled } from 'shared/components/ihcl/styled';

import { Checkbox } from 'shared/components/ihcl/checkbox';
import { Input } from 'shared/components/ihcl/input';
import { Center } from 'shared/components/ihcl/positioning';
import { LucideIcon } from 'shared/components/ihcl/lucideIcon';

import {
  Onboarding,
  OnboardingSubtitle,
  OnboardingTitle,
} from 'registration/containers/Onboarding';
import { Text } from 'shared/components/ihcl/text';
import { OnboardingContext } from '../../containers/Onboarding';

const InputWrapper = styled('div', ({ $theme }) => ({
  marginBottom: $theme.sizing.unit24,
}));

// eslint-disable-next-line react/require-default-props
const OnboardingResume = ({
  nextAction = null,
  onboardingWrapper = null,
  onboardingWrapperProps = {},
  previousAction = null,
  stepNumber,
}) => {
  const {
    // @ts-ignore
    onboardingData,
    // @ts-ignore
    setOnboardingData,
  } = useContext(OnboardingContext);
  const [pendingSelectedOptionsSet, setPendingSelectedOptionsSet] = useState(
    new Set()
  );
  const [filterCertificatesValue, setFilterCertificatesValue] = useState('');
  useEffect(() => {
    // TODO: Replace with correct certificate identifiers when ready
    setOnboardingData({
      ...onboardingData,
      certificates: pendingSelectedOptionsSet,
    });
  }, [pendingSelectedOptionsSet, setOnboardingData]);

  if (!onboardingData || Object.keys(onboardingData).length < 1) {
    return null;
  }

  // TODO: Replace this placeholder data with data from the DB when it's ready.
  const certificationOptions = [
    {
      name: 'ABRET',
      details:
        'American Board of Registration of Electroencephalographic Technologists',
    },
    { name: 'AMRIT-B', details: 'Advanced MRI Technologist in Breast' },
    { name: 'AMRIT-C', details: 'Advanced MRI Technologist in Cardiac' },
    { name: 'AMRIT-NS', details: 'Advanced MRI Technologist in Neuro & Spine' },
    {
      name: 'ARDMS',
      details: 'American Registry for Diagnostic Medical Sonography',
    },
  ];

  return (
    <Onboarding
      currentStep={stepNumber}
      isNextDisabled={false}
      nextAction={nextAction}
      previousAction={previousAction}
      stepKeys={['workExperiences', 'workExperienceMethod']}
      onboardingWrapper={onboardingWrapper}
      onboardingWrapperProps={onboardingWrapperProps}
      validateBeforeSubmit={() => true}
    >
      <OnboardingTitle $reducedFontSize $additionalTopSpacing>
        What certifications and licenses do you have?
      </OnboardingTitle>
      <OnboardingSubtitle $noTopMargin>
        <Center>Please select all that apply</Center>
      </OnboardingSubtitle>
      <InputWrapper>
        <Input
          startEnhancer={<LucideIcon name="Search" size="unit16" />}
          placeholder="Look up certification or license"
          value={filterCertificatesValue}
          onChange={(e) => setFilterCertificatesValue(e.target.value)}
        />
      </InputWrapper>
      {certificationOptions
        .filter((filterOption) =>
          Boolean(
            `${filterOption.name}`.match(
              new RegExp(filterCertificatesValue, 'i')
            )
          )
        )
        .map((option: { name: string; details: string }) => (
          <Checkbox
            key={option.name}
            ariaLabel={option.name}
            checked={
              pendingSelectedOptionsSet &&
              pendingSelectedOptionsSet.has(option.name)
            }
            onChange={(e) => {
              const newPendingSelectedOptionsSet = structuredClone(
                pendingSelectedOptionsSet
              );
              if (e.target.checked) {
                newPendingSelectedOptionsSet.add(option.name);
              } else {
                newPendingSelectedOptionsSet.delete(option.name);
              }
              setPendingSelectedOptionsSet(newPendingSelectedOptionsSet);
            }}
            size="large"
          >
            <Text variant="ParagraphMedium">{option.name}</Text>
            <Text variant="ParagraphXSmall">{option.details}</Text>
          </Checkbox>
        ))}
    </Onboarding>
  );
  /* TODO: Add custom certification functionality */
};
OnboardingResume.propTypes = {
  nextAction: PropTypes.string,
  onboardingWrapper: PropTypes.func,
  onboardingWrapperProps: PropTypes.object,
  previousAction: PropTypes.string,
  stepNumber: PropTypes.number.isRequired,
};

export default OnboardingResume;
