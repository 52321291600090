import React from 'react';
import { styled } from 'shared/components/ihcl/styled';
import { Theme } from 'shared/components/ihcl/theme-provider';

const keyframe =
  '@keyframes l3 {' +
  '20%{background-position:0%   0%, 50%  50%,100%  50%}' +
  '40%{background-position:0% 100%, 50%   0%,100%  50%}' +
  '60%{background-position:0%  50%, 50% 100%,100%   0%}' +
  '80%{background-position:0%  50%, 50%  50%,100% 100%};' +
  '}';

type LoaderProps = {
  $color: string;
};

// animation comes from https://css-loaders.com/dots/ #3
const Loader = styled<'div', LoaderProps>('div', ({ $theme, $color }) => ({
  width: '45px',
  aspectRatio: 2,
  '--_g': `no-repeat radial-gradient(circle closest-side,${$theme.colors[$color]} 90%,#0000)`,
  background: 'var(--_g) 0% 50%, var(--_g) 50%  50%, var(--_g) 100% 50%',
  backgroundSize: 'calc(100%/3) 50%',
  animation: 'l3 1s infinite linear',
}));

const LoadingDots = ({
  color = 'black',
}: {
  color?: keyof Theme['colors'];
}) => (
  <>
    <style>{keyframe}</style>
    <Loader $color={color} />
  </>
);

export default LoadingDots;
