import { createAction } from 'redux-actions';

import { doPatch } from 'shared/helpers/http';

import { displayErrorDialog } from './errorActions';

export const updateTechProfileStarted = createAction(
  'UPDATE_TECH_PROFILE_STARTED'
);

export const updateTechProfileComplete = createAction(
  'UPDATE_TECH_PROFILE_COMPLETE'
);

export const updateTechProfileError = createAction('UPDATE_TECH_PROFILE_ERROR');

export const serverErrorsReceived = createAction(
  'TECH_PROFILE_SERVER_ERRORS_RECEIVED'
);

export function updateTechProfile(tpId, tp, afterUpdateAction) {
  return (dispatch) => {
    dispatch(updateTechProfileStarted());

    return doPatch(`/tech_profile/${tpId}`, tp)
      .then((json) => {
        dispatch(updateTechProfileComplete(json));
        dispatch(serverErrorsReceived({}));
        afterUpdateAction();
      })
      .catch((error) => {
        dispatch(updateTechProfileError());
        if (error.response && error.response.status === 422) {
          error.response
            .json()
            .then((json) => dispatch(serverErrorsReceived(json)));
        } else {
          console.error(error);
          const errorMsg =
            'Oops, there was a problem connecting to the ' +
            'server. Please check your internet connection and refresh the ' +
            'page.';
          dispatch(displayErrorDialog(errorMsg));
        }
      });
  };
}
