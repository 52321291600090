import React from 'react';
import { styled } from 'shared/components/ihcl/styled';
import { Text } from 'shared/components/ihcl/text';

const Detail = styled('div', ({ $theme }) => ({
  backgroundColor: $theme.colors.inputBackground,
  display: 'flex',
  flexDirection: 'column',
  gap: $theme.sizing.unit2,
  paddingTop: $theme.sizing.unit8,
  paddingLeft: $theme.sizing.unit16,
  paddingRight: $theme.sizing.unit16,
  paddingBottom: $theme.sizing.unit8,
  borderRadius: $theme.sizing.unit8,
}));

const AppointmentDetail = ({ label, copy }: { label: string; copy: any }) => (
  <Detail>
    <Text color="grayLabel" variant="LabelSmall">
      {label}
    </Text>
    <Text color="bodyCopyGray" variant="LabelMedium">
      <span className="sentry-mask">{copy}</span>
    </Text>
  </Detail>
);

export default AppointmentDetail;
