import React, { useContext, useRef } from 'react';
import PropTypes from 'prop-types';

import { Center } from 'shared/components/ihcl/positioning';
import { Input } from 'shared/components/ihcl/input';
import {
  Onboarding,
  OnboardingTitle,
  OnboardingSubtitle,
} from 'registration/containers/Onboarding';
import parseFullName from '../../helpers/parseFullName';
import { OnboardingContext } from '../../containers/Onboarding';
import OnboardingTestimonial from './OnboardingTestimonial';

// eslint-disable-next-line react/require-default-props
const OnboardingName = ({
  nextAction = null,
  previousAction = null,
  stepNumber,
}) => {
  const { onboardingData, setOnboardingData } = useContext(OnboardingContext);
  const nextActionButtonRef = useRef(null);
  if (!onboardingData || Object.keys(onboardingData).length < 1) {
    return null;
  }
  const { errors, full_name: fullName } = onboardingData;

  let nameError = null;
  if (errors && errors.name && Array.isArray(errors.name)) {
    nameError = errors.name.join('; ');
  }

  const setName = (name, errorMessage) => {
    const [firstName, lastName] = parseFullName(name);
    const nameObj = {
      full_name: name,
    };
    if (lastName) {
      nameObj.first_name = firstName;
      nameObj.last_name = lastName;
      setOnboardingData({
        ...onboardingData,
        ...nameObj,
      });
    } else {
      setOnboardingData({
        ...onboardingData,
        ...nameObj,
        errors: {
          name: [errorMessage],
        },
      });
    }
  };

  return (
    <Onboarding
      currentStep={stepNumber}
      isNextDisabled={parseFullName(fullName).length < 2}
      nextAction={nextAction}
      nextActionButtonRef={nextActionButtonRef}
      previousAction={previousAction}
      stepKeys={['first_name', 'last_name']}
    >
      <Center>
        <OnboardingTitle>What&apos;s your name?</OnboardingTitle>
        <Center>
          <OnboardingSubtitle>
            Kick back and relax while hospitals compete for you.
          </OnboardingSubtitle>
          <form
            action="#"
            onSubmit={(evt) => {
              evt.preventDefault();
              if (
                parseFullName(fullName).length >= 2 &&
                nextActionButtonRef &&
                nextActionButtonRef.current
              ) {
                nextActionButtonRef.current.click();
              }
            }}
          >
            <Input
              id="fullName"
              value={fullName}
              type="text"
              label="Your first & last name"
              onChange={(evt) => {
                setName(evt.target.value, null);
              }}
              onBlur={(evt) => {
                const parsedFullName = parseFullName(evt.target.value);
                if (parsedFullName.length < 2) {
                  setName(
                    evt.target.value,
                    'Please enter your first and last name.'
                  );
                }
              }}
              error={!!nameError}
              caption={nameError}
            />
          </form>
          <OnboardingTestimonial
            author="Heather Banks, ICU RN"
            quote="Great support system throughout the entire process of prepping for interviews and accepting a job offer!"
          />
        </Center>
      </Center>
    </Onboarding>
  );
};
OnboardingName.propTypes = {
  nextAction: PropTypes.string,
  previousAction: PropTypes.string,
  stepNumber: PropTypes.number.isRequired,
};

export default OnboardingName;
