import PropTypes from 'prop-types';
import React from 'react';
import { OnboardingTitle } from 'registration/components/new_pages/OnboardingCurrentLocation/OnboardingTitle';
import { OtherLocations } from 'registration/components/new_pages/OnboardingCurrentLocation/OtherLocations';
import { TagLocationPinIcon } from 'registration/containers/Onboarding';
import { Button } from 'shared/components/ihcl/button';
import { Center } from 'shared/components/ihcl/positioning';
import { Tag, SIZE as TAG_SIZE } from 'shared/components/ihcl/tag';

// eslint-disable-next-line react/require-default-props
export const ExistingLocation = ({
  currentLocation,
  setCurrentLocation,
  // eslint-disable-next-line react/require-default-props
  isRecentGrad,
  // eslint-disable-next-line react/require-default-props
  openToRelocation,
  setOpenToRelocation,
  // eslint-disable-next-line react/require-default-props
  stillAtCurrentLocation,
  setStillAtCurrentLocation,
}) => (
  <Center>
    <OnboardingTitle>Are you still living here?</OnboardingTitle>
    <Center>
      <Tag
        style={{
          marginTop: '16px',
        }}
        $fadeIn
        $fullWidth
        closeable={false}
        size={TAG_SIZE.medium}
      >
        <div>
          <TagLocationPinIcon />
          {currentLocation.label}
        </div>
      </Tag>
      <br />
      <div
        style={{
          marginTop: '16px',
        }}
      />
      <Button
        onClick={() => {
          setStillAtCurrentLocation(false);
          setCurrentLocation(null);
        }}
        kind={stillAtCurrentLocation ? 'tertiary' : 'primary'}
        style={{
          minWidth: '100px',
          marginRight: '32px',
        }}
      >
        No
      </Button>
      <Button
        onClick={() => setStillAtCurrentLocation(true)}
        kind={stillAtCurrentLocation ? 'primary' : 'tertiary'}
        style={{
          minWidth: '100px',
        }}
      >
        Yes
      </Button>
      <div
        style={{
          visibility: stillAtCurrentLocation ? 'visible' : 'hidden',
          marginTop: '40px',
        }}
      >
        <OtherLocations
          openToRelocation={openToRelocation}
          setOpenToRelocation={setOpenToRelocation}
          isRecentGrad={isRecentGrad}
        />
      </div>
    </Center>
  </Center>
);
ExistingLocation.defaultProps = {
  isRecentGrad: false,
  openToRelocation: null,
  stillAtCurrentLocation: null,
};
ExistingLocation.propTypes = {
  currentLocation: PropTypes.shape({
    city: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    zipCode: PropTypes.string,
  }).isRequired,
  isRecentGrad: PropTypes.bool,
  openToRelocation: PropTypes.bool,
  setCurrentLocation: PropTypes.func.isRequired,
  setOpenToRelocation: PropTypes.func.isRequired,
  setStillAtCurrentLocation: PropTypes.func.isRequired,
  stillAtCurrentLocation: PropTypes.bool,
};
