import { createTheme, LightTheme } from 'baseui';
import getTypography from 'baseui/themes/shared/typography';

export function alpha(hexColor, percentage) {
  const hexRange = Math.round((percentage / 100.0) * 255);
  const hexPercentage = hexRange.toString(16).padStart(2, '0');
  return `${hexColor}${hexPercentage}`;
}

export function cssValueMath(val1, operator, val2) {
  if (!val1 && val2) {
    // eslint-disable-next-line no-console
    console.warn('cssValueMath: val1 is undefined');
    return val2;
  }
  if (val1 && !val2) {
    // eslint-disable-next-line no-console
    console.warn('cssValueMath: val2 is undefined');
    return val1;
  }
  const numUnitRegex = /[\d]+|[\D]+/g;
  const [num1, unit1] = val1.match(numUnitRegex);
  const [num2, unit2] = val2.match(numUnitRegex);
  if (unit1 === unit2) {
    let resultNum;
    switch (operator) {
      case '+':
        resultNum = parseFloat(num1) + parseFloat(num2);
        break;
      case '-':
        resultNum = num1 - num2;
        break;
      case '*':
        resultNum = num1 * num2;
        break;
      case '/':
        resultNum = num1 / num2;
        break;
      default:
        console.error(`Unsupported operator '${operator}'`);
        break;
    }
    return `${resultNum}${unit1}`;
  }
  return `calc(${val1} ${operator} ${val2})`;
}

function getLetterSpacing(styles) {
  let letterSpacing = '0.25px';
  if (styles.fontSize) {
    const fontSizeMatch = styles.fontSize.match(/(\d*)px/);
    if (fontSizeMatch && fontSizeMatch[1] < 14) {
      letterSpacing = '0.5px';
    }
  }
  return letterSpacing;
}

export function setTypography(themeTypography) {
  return Object.fromEntries(
    Object.entries(themeTypography).map(([key, value]) => [
      key,
      { ...value, letterSpacing: getLetterSpacing(value) },
    ])
  );
}

export const fontTokens = {
  primaryFontFamily: 'Manrope, Helvetica, Arial, sans-serif',
};

export const incredibleColors = {
  white: '#FFFFFF',
  black: '#14142A',
  blackAlpha8: alpha('#14142A', 8),
  blackAlpha12: alpha('#14142A', 12),
  blackAlpha15: alpha('#14142A', 15),
  blackAlpha25: alpha('#14142A', 25),
  blackAlpha40: alpha('#14142A', 40),
  blackAlpha50: alpha('#14142A', 50),
  blackAlpha60: alpha('#14142A', 60),
  blackAlpha70: alpha('#14142A', 70),
  bodyCopyGray: '#4E4B66',
  borderMuted: '#2A303D1F',
  borderNeutral: '#2A303D3D',
  ebonyClay: '#2A303D',
  gray: '#796E91',
  gray00: '#F2F0F4',
  grayBackground: '#F9F9FB',
  grayBodyCopy: '#4E4B66',
  grayLine: '#C7C5D3',
  grayLabel: '#78768A',
  grayPlaceholder: '#AFA8BD',
  primary: '#3F64E9',
  primary90: '#212C64',
  primary80: '#253477',
  primary70: '#2A3C8A',
  primary60: '#2E449D',
  primary40: '#8CA2F2',
  primary30: '#9FB2F4',
  primary20: '#B2C1F6',
  primary10: '#C5D0F8',
  primary00: '#ECEFFD',
  primaryAlpha15: alpha('#3F64E9', 15),
  primaryAlpha30: alpha('#3F64E9', 30),
  primaryAlpha60: alpha('#3F64E9', 60),
  primary60Alpha60: alpha('#2E449D', 60),
  primaryLight: '#11BBF1',
  accent: '#C127DA',
  accent80: '#591B71',
  accent60: '#7C1F94',
  accent30: '#E093ED',
  accent10: '#ECBEF4',
  accent00: '#F9E9FB',
  accentAlpha15: alpha('#C127DA', 15),
  accentAlpha30: alpha('#C127DA', 30),
  iconMuted: '#AAA8BD',
  inputBackground: '#F2F0F4',
  negative: '#E7454F',
  negativeAlpha15: alpha('#E7454F', 15),
  negativeAlpha30: alpha('#E7454F', 30),
  negative00: '#FEF0EE',
  negative05: '#FCECED',
  negative40: '#91382C',
  negative60: '#60251D',
  negative70: '#7D2C3D',
  neutral: '#EAE8EF',
  silver: '#C4C4C4',
  warning: '#FFC32B',
  warning70: '#8A6B2A',
  warning00: '#FFF9EA',
  positive: '#0FB990',
  positive70: '#12665D',
  positive00: '#E7F8F4',
  yellow70: '#BB7F00',
  darkBlue: '#00679B',
  darkBlue5: '#F2F7FA',
  darkBlue10: '#E6F0F5',
  darkBlue20: '#00527C',
  darkBlue40: '#003E5D',
  darkBlue60: alpha('#00679B', 60),
  marigold: '#DFB525',
  marigold10: '#FAF6E6',
  marigold60: '#EACE7E',
  marigold20Black: '#B2811E',
  marigold40Black: '#866116',
  marigold60Black: '#59480F',
  seaGreen: '#1B8F51',
  seaGreen10: '#E7F6EE',
  seaGreen60: '#6EC694',
  seaGreen60Black: '#0B3920',
  coolGradient: 'linear-gradient(114.44deg, #7433FF 0%, #C127DA 100%)',
  warmGradient: 'linear-gradient(114.44deg, #E7454F 0%, #FFC42B 100%)',
  dolphin: '#6B6B79', //  from bootstrap
};

const buttonBase = {
  outlineWidth: '0px',
  focusOutlineWidth: '3px',

  outlineColor: 'transparent',
  hoverOutlineColor: 'transparent',
  activeOutlineColor: 'transparent',

  disabledBackgroundColor: incredibleColors.gray00,
  disabledOutlineColor: 'transparent',
  disabledTextColor: incredibleColors.grayLine,
};

const buttonTextColors = {
  textColor: incredibleColors.primary,
  hoverTextColor: incredibleColors.primary60,
  activeColor: incredibleColors.primary80,
};
const buttonAlternateTextColors = {
  textColor: incredibleColors.accent,
  hoverTextColor: incredibleColors.accent60,
  activeColor: incredibleColors.accent80,
};
const buttonAlternateDarkTextColors = {
  textColor: incredibleColors.accent80,
  hoverTextColor: incredibleColors.accent60,
  activeColor: incredibleColors.accent80,
};

const buttonColorBase = {
  primary: {
    ...buttonBase,
    textColor: incredibleColors.white,
    hoverTextColor: incredibleColors.white,
    activeColor: incredibleColors.white,

    backgroundColor: incredibleColors.primary,
    hoverBackgroundColor: incredibleColors.primary60,
    activeBackgroundColor: incredibleColors.primary80,

    focusOutlineColor: incredibleColors.primary40,
  },
  tertiary: {
    ...buttonBase,
    ...buttonTextColors,
    backgroundColor: incredibleColors.primary00,
    hoverBackgroundColor: incredibleColors.primary10,
    activeBackgroundColor: incredibleColors.primary40,

    focusOutlineColor: incredibleColors.primary10,
  },
  outline: {
    ...buttonBase,
    ...buttonTextColors,
    disabledOutlineColor: incredibleColors.blackAlpha12,
    disabledBackgroundColor: 'transparent',

    outlineWidth: '1px',

    backgroundColor: 'transparent',
    hoverBackgroundColor: incredibleColors.primaryAlpha15,
    activeBackgroundColor: incredibleColors.primaryAlpha30,

    outlineColor: incredibleColors.primaryAlpha60,
    hoverOutlineColor: incredibleColors.primary60Alpha60,
    activeOutlineColor: incredibleColors.primary60Alpha60,
    focusOutlineColor: incredibleColors.primaryAlpha15,
  },
  minimal: {
    ...buttonBase,
    ...buttonTextColors,
    disabledBackgroundColor: 'transparent',

    backgroundColor: 'transparent',
    hoverBackgroundColor: incredibleColors.primaryAlpha15,
    activeBackgroundColor: incredibleColors.primaryAlpha30,

    focusOutlineColor: incredibleColors.primaryAlpha15,
  },
};

export const incredibleTheme = createTheme(LightTheme, {
  borders: {
    buttonBorderWidth: '3px',
    buttonBorderRadius: '8px',
    buttonFocusRingWidth: '4px',
    buttonSecondaryBorderWidth: '2px',
    checkboxBorderRadius: '4px',
    checkboxBorderWidth: '1px',
    footnoteBorderWidth: '2px',
    footnoteBorderRadius: '8px',
    inputBorderRadius: '8px',
    inputBorderWidth: '2px',
    surfaceBorderRadius: '0px',
    tagBorderWidth: '2px',
    // These two would ideally not be 0, but they are used directly in the
    // styled components of 2 Base Web components that we use - Card and Modal.
    // For now, these are just pass through components, but at some point we
    // should export overridden components that set the border radius within
    // our ihcl components.
    radius400: '0px',
    radius500: '0px',
  },
  badgeColors: {
    accent: {
      backgroundColor: incredibleColors.accent00,
      textColor: incredibleColors.accent60,
    },
    primary: {
      backgroundColor: incredibleColors.gray00,
      textColor: incredibleColors.bodyCopyGray,
    },
  },
  buttonColors: {
    primary: buttonColorBase.primary,
    tertiary: buttonColorBase.tertiary,
    outline: buttonColorBase.outline,
    minimal: buttonColorBase.minimal,
    primaryAlternate: {
      ...buttonColorBase.primary,
      backgroundColor: incredibleColors.accent,
      hoverBackgroundColor: incredibleColors.accent60,
      activeBackgroundColor: incredibleColors.accent80,

      focusOutlineColor: incredibleColors.accent60,
    },
    primaryAlternateDark: {
      ...buttonColorBase.primary,
      backgroundColor: incredibleColors.accent60,
      hoverBackgroundColor: incredibleColors.accent80,
      activeBackgroundColor: incredibleColors.accent80,

      focusOutlineColor: incredibleColors.accent30,
    },
    primaryNegative: {
      ...buttonColorBase.primary,
      backgroundColor: incredibleColors.negative,
      hoverBackgroundColor: incredibleColors.negative40,
      activeBackgroundColor: incredibleColors.negative60,
      focusOutlineColor: incredibleColors.negative40,
    },
    tertiaryAlternate: {
      ...buttonColorBase.tertiary,
      ...buttonAlternateTextColors,
      backgroundColor: incredibleColors.accent00,
      hoverBackgroundColor: incredibleColors.accent10,
      activeBackgroundColor: incredibleColors.accent30,

      focusOutlineColor: incredibleColors.accent30,
    },
    tertiaryAlternateDark: {
      ...buttonColorBase.tertiary,
      ...buttonAlternateDarkTextColors,
      backgroundColor: incredibleColors.accent30,
      hoverBackgroundColor: incredibleColors.accent10,
      activeBackgroundColor: incredibleColors.accent30,

      focusOutlineColor: incredibleColors.accent60,
    },
    outlineAlternate: {
      ...buttonColorBase.outline,
      ...buttonAlternateTextColors,
      hoverBackgroundColor: incredibleColors.accentAlpha15,
      activeBackgroundColor: incredibleColors.accentAlpha30,

      outlineColor: incredibleColors.accent,
      hoverOutlineColor: incredibleColors.accent60,
      activeOutlineColor: incredibleColors.accent60,

      focusOutlineColor: incredibleColors.accent,
    },
    outlineAlternateDark: {
      ...buttonColorBase.outline,
      ...buttonAlternateDarkTextColors,
      hoverBackgroundColor: incredibleColors.accentAlpha15,
      activeBackgroundColor: incredibleColors.accentAlpha30,

      outlineColor: incredibleColors.accent60,
      hoverOutlineColor: incredibleColors.accent30,
      activeOutlineColor: incredibleColors.accent80,

      focusOutlineColor: incredibleColors.accent,
    },
    outlineNegative: {
      ...buttonColorBase.outline,
      textColor: incredibleColors.negative,
      hoverBackgroundColor: incredibleColors.negativeAlpha15,
      activeBackgroundColor: incredibleColors.negativeAlpha30,
      outlineColor: incredibleColors.negative,
      hoverOutlineColor: incredibleColors.negative60,
      activeOutlineColor: incredibleColors.negative60,
      focusOutlineColor: incredibleColors.negative,
    },
    outlineNeutral: {
      ...buttonColorBase.outline,
      activeColor: incredibleColors.bodyCopyGray,
      textColor: incredibleColors.bodyCopyGray,
      hoverBackgroundColor: incredibleColors.blackAlpha8,
      hoverTextColor: incredibleColors.bodyCopyGray,
      activeBackgroundColor: incredibleColors.blackAlpha15,
      outlineColor: incredibleColors.blackAlpha25,
      hoverOutlineColor: incredibleColors.blackAlpha60,
      activeOutlineColor: incredibleColors.blackAlpha60,
      focusOutlineColor: incredibleColors.grayPlaceholder,
    },
    minimalNegative: {
      ...buttonColorBase.minimal,
      textColor: incredibleColors.negative,
      hoverTextColor: incredibleColors.negative,
      hoverBackgroundColor: incredibleColors.negativeAlpha15,
      activeBackgroundColor: incredibleColors.negativeAlpha30,
      outlineColor: incredibleColors.negative,
      activeOutlineColor: incredibleColors.negative60,
      focusOutlineColor: incredibleColors.negative,
    },
    minimalNeutral: {
      ...buttonColorBase.minimal,
      activeColor: incredibleColors.bodyCopyGray,
      textColor: incredibleColors.bodyCopyGray,
      hoverBackgroundColor: incredibleColors.blackAlpha8,
      hoverTextColor: incredibleColors.bodyCopyGray,
      activeBackgroundColor: incredibleColors.blackAlpha15,
      outlineColor: incredibleColors.blackAlpha25,
      hoverOutlineColor: incredibleColors.blackAlpha60,
      activeOutlineColor: incredibleColors.blackAlpha60,
      focusOutlineColor: incredibleColors.grayPlaceholder,
    },
    minimalAlternate: {
      ...buttonColorBase.minimal,
      ...buttonAlternateTextColors,
      hoverBackgroundColor: incredibleColors.accentAlpha15,
      activeBackgroundColor: incredibleColors.accentAlpha30,

      focusOutlineColor: incredibleColors.accent30,
    },
    primaryNeutral: {
      ...buttonColorBase.primary,
      textColor: incredibleColors.white,
      backgroundColor: incredibleColors.bodyCopyGray,
      hoverBackgroundColor: incredibleColors.black,
      activeBackgroundColor: incredibleColors.black,

      focusOutlineColor: incredibleColors.grayPlaceholder,
    },
    tertiaryNeutral: {
      ...buttonColorBase.primary,
      textColor: incredibleColors.bodyCopyGray,
      backgroundColor: incredibleColors.neutral,
      hoverBackgroundColor: incredibleColors.grayPlaceholder,
      activeBackgroundColor: incredibleColors.gray00,

      focusOutlineColor: incredibleColors.grayPlaceholder,
    },
  },
  colors: {
    ...incredibleColors,
    borderFocus: incredibleColors.primary,
    borderSelected: incredibleColors.primary,
    // The button* colors override baseweb's button theme. These overrides do
    // not exactly match the ihcl/button component's styling, but they do ensure
    // any component not overriding the BaseButton will have sensible defaults.
    buttonPrimaryFocusBorder: incredibleColors.primary40,
    buttonPrimaryText: incredibleColors.white,
    buttonMinimalText: incredibleColors.primary,
    buttonMinimalTextHover: incredibleColors.primary60,
    buttonMinimalHover: incredibleColors.gray00,
    buttonSecondaryFill: incredibleColors.white,
    buttonSecondaryActive: incredibleColors.white,
    buttonSecondaryActiveBorder: '#12309f',
    buttonSecondaryBorder: incredibleColors.primary,
    buttonSecondaryHoverBorder: '#1841d4',
    buttonSecondaryHover: incredibleColors.white,
    buttonSecondaryText: incredibleColors.primary,
    buttonSecondaryFocusBorder: incredibleColors.primary40,
    buttonTertiaryFill: incredibleColors.primary00,
    buttonTertiaryText: incredibleColors.primary60,
    buttonOutlineColor: incredibleColors.primary40,
    calendarDayBackgroundSelectedHighlighted: incredibleColors.primary30,
    calendarHeaderBackground: incredibleColors.white,
    calendarHeaderForeground: incredibleColors.black,
    checkboxDisabledFill: incredibleColors.primary,
    checkboxPrimaryFill: incredibleColors.white,
    inputFill: incredibleColors.white,
    inputFillActive: incredibleColors.white,
    inputBorderPositive: incredibleColors.positive,
    inputFillPositive: incredibleColors.positive00,
    inputLabelPositive: incredibleColors.positive,
    inputFillError: incredibleColors.negative05,
    inputLabelError: incredibleColors.negative,
    onboardingBackground: '#F9FAFB',
    sliderTrackFillActive: incredibleColors.primary,
    tagAccentOutlinedBackground: incredibleColors.accent,
    tagAccentSolidBackground: incredibleColors.accent,
    tagPrimarySolidFont: incredibleColors.primary,
    tagPrimarySolidBackground: incredibleColors.primary00,
    talentJobsPageBackground: '#F9FAFB',
    tickBorder: incredibleColors.grayLine,
    toastNegativeBackground: incredibleColors.negative,
    toggleFill: incredibleColors.white,
    toggleFillChecked: incredibleColors.accent,
    toggleFillDisabled: incredibleColors.accent30,
    toggleTrackFill: incredibleColors.accent10,
    toggleTrackFillDisabled: incredibleColors.accentAlpha30,
  },
});

incredibleTheme.mediaQuery.sm = '@media screen and (min-width: 768px)';
incredibleTheme.mediaQuery.md = '@media screen and (min-width: 992px)';
incredibleTheme.mediaQuery.lg = '@media screen and (min-width: 1200px)';

const { breakpoints: bps } = incredibleTheme;
incredibleTheme.mediaQuery.lt = {
  small: `@media screen and (max-width: ${bps.small - 1}px)`,
  medium: '@media screen and (max-width: 600px)',
  md: '@media screen and (max-width: 991px)',
  large: '@media screen and (max-width: 1135px)',
};

export const incredibleSizing = {
  inputHeight: '56px',
  inputCompactHeight: '40px',
  inputCompactPadding: '16px',
  inputCompactPaddingTop: '12px',
  inputTinyHeight: '36px',
  inputTinyPadding: '12px',
  inputMinimalPadding: '6px',
  inputTinyPaddingTop: '0',
  inputPaddingLeft: '14px',
  inputPaddingTop: '26px',
  mobileNavbarHeight: '60px',
  desktopNavbarHeight: '80px',
  // the 83.333 and 8.333 are derived from the bootstrap column sizes
  // once we are off bootstrap should be able to make these whatever we want
  employerContainerWidth: '83.333%',
  employerContainerMargin: '8.333%',
  unit2: '2px',
  unit4: '4px',
  unit6: '6px',
  unit8: '8px',
  unit10: '10px',
  unit12: '12px',
  unit14: '14px',
  unit16: '16px',
  unit18: '18px',
  unit20: '20px',
  unit22: '22px',
  unit24: '24px',
  unit28: '28px',
  unit32: '32px',
  unit36: '36px',
  unit40: '40px',
  unit48: '48px',
  unit56: '56px',
  unit64: '64px',
  unit96: '96px',
  unit128: '128px',
  unit192: '192px',
};

incredibleTheme.sizing = {
  ...incredibleTheme.sizing,
  ...incredibleSizing,
};

// Verify we have full parity between scale and unit values in the theme
(() => {
  Object.entries(incredibleTheme.sizing)
    .filter(([name]) => name.match(/scale/))
    .forEach(([, value]) => {
      const unitLessValue = value.replace('px', '');
      if (incredibleTheme.sizing[`unit${unitLessValue}`] !== value) {
        console.error(
          `Missing 'unit${unitLessValue}: ${value}' in incredibleTheme.sizing`
        );
      }
    });
})();

incredibleTheme.lighting.shadow400 = `0px 2px 4px ${alpha('#000000', 29)}`;
incredibleTheme.lighting.shadow450 = `0px 2px 4px ${alpha('#000000', 16)}`;
incredibleTheme.lighting.shadow500 = `0px 2px 6px ${incredibleColors.blackAlpha12}`;
incredibleTheme.lighting.shallowAbove = `0px -2px 4px 0px ${alpha(
  '#000000',
  25
)}`;
incredibleTheme.lighting.deepAbove = `0px -1px 12px 0px ${alpha(
  '#000000',
  25
)}`;
incredibleTheme.lighting.shadowBelow = `0px 4px 12px 0px ${incredibleColors.blackAlpha25}`;

incredibleTheme.typography = setTypography(getTypography(fontTokens));
incredibleTheme.typography = {
  ...incredibleTheme.typography,
  font50: {
    ...incredibleTheme.typography.font100,
    fontSize: '10px',
    lineHeight: '12px',
  },
  LabelXSmallCap: {
    fontFamily: fontTokens.primaryFontFamily,
    fontSize: '12px',
    fontWeight: '500',
    lineHeight: '120%',
    letterSpacing: '0.5px',
    textTransform: 'uppercase',
  },
  LabelXSmall: {
    fontFamily: fontTokens.primaryFontFamily,
    fontSize: '12px',
    fontWeight: '500',
    lineHeight: '120%',
    letterSpacing: '0.5px',
  },
  LabelSmall: {
    fontFamily: fontTokens.primaryFontFamily,
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '120%',
    letterSpacing: '0.25px',
  },
  LabelMedium: {
    fontFamily: fontTokens.primaryFontFamily,
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '120%',
    letterSpacing: '0.25px',
  },
  LabelLarge: {
    fontFamily: fontTokens.primaryFontFamily,
    fontSize: '18px',
    fontWeight: '500',
    lineHeight: '120%',
    letterSpacing: '0.25px',
  },
  LabelXSmallCapBold: {
    fontFamily: fontTokens.primaryFontFamily,
    fontSize: '12px',
    fontWeight: '800',
    lineHeight: '120%',
    letterSpacing: '0.5px',
    textTransform: 'uppercase',
  },
  LabelXSmallBold: {
    fontFamily: fontTokens.primaryFontFamily,
    fontSize: '12px',
    fontWeight: '800',
    lineHeight: '120%',
    letterSpacing: '0.5px',
  },
  LabelSmallCapBold: {
    fontFamily: fontTokens.primaryFontFamily,
    fontSize: '14px',
    fontWeight: '800',
    lineHeight: '120%',
    letterSpacing: '0.25px',
    textTransform: 'uppercase',
  },
  LabelSmallBold: {
    fontFamily: fontTokens.primaryFontFamily,
    fontSize: '14px',
    fontWeight: '800',
    lineHeight: '120%',
    letterSpacing: '0.25px',
  },
  LabelMediumBold: {
    fontFamily: fontTokens.primaryFontFamily,
    fontSize: '16px',
    fontWeight: '800',
    lineHeight: '120%',
    letterSpacing: '0.25px',
  },
  LabelLargeBold: {
    fontFamily: fontTokens.primaryFontFamily,
    fontSize: '18px',
    fontWeight: '800',
    lineHeight: '120%',
    letterSpacing: '0.25px',
  },
  DisplayXSmall: {
    fontFamily: fontTokens.primaryFontFamily,
    fontSize: '12px',
    letterSpacing: '0.5px',
  },
  DisplaySmall: {
    fontFamily: fontTokens.primaryFontFamily,
    fontSize: '14px',
    letterSpacing: '0.25px',
  },
  DisplayMedium: {
    fontFamily: fontTokens.primaryFontFamily,
    fontSize: '16px',
    letterSpacing: '0.25px',
  },
  DisplayLarge: {
    fontFamily: fontTokens.primaryFontFamily,
    fontSize: '18px',
    letterSpacing: '0.25px',
  },
  DisplayExtraLarge: {
    fontFamily: fontTokens.primaryFontFamily,
    fontSize: '32px',
    letterSpacing: '0.25px',
    fontWeight: 800,
  },
};

incredibleTheme.borders.buttonSecondaryMargin = cssValueMath(
  incredibleTheme.borders.buttonFocusRingWidth,
  '-',
  incredibleTheme.borders.buttonSecondaryBorderWidth
);

// Container queries are currently unsupported in Styletron :(
/*
const mediaObjsToContainerObjs = (mediaObj) =>
  Object.entries(mediaObj).reduce((queryObj, [key, val]) => {
    const newQueryObj = { ...queryObj };
    if (typeof val === 'string') {
      newQueryObj[key] = val.replace('@media screen and', '@container');
    } else {
      newQueryObj[key] = mediaObjsToContainerObjs(val);
    }
    return newQueryObj;
  }, {});

incredibleTheme.containerQuery = mediaObjsToContainerObjs(
  incredibleTheme.mediaQuery
);
 */
