import React from 'react';
import { LucideIcon } from 'shared/components/ihcl/lucideIcon';
import { styled } from 'shared/components/ihcl/styled';
import { Text } from 'shared/components/ihcl/text';

export const TitleWrapper = styled(Text, ({ $theme }) => ({
  paddingTop: $theme.sizing.unit24,
  paddingBottom: $theme.sizing.unit24,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'top',
  gap: $theme.sizing.unit32,
}));

const Title = ({
  onClick = null,
  children,
}: {
  onClick?: Function;
  children: any;
}) => (
  <TitleWrapper variant="HeadingSmall">
    {children}
    {onClick && (
      <LucideIcon
        name="X"
        size="32px"
        onClick={() => onClick()}
        color="#78768A"
        data-testid="screening-modal-close"
      />
    )}
  </TitleWrapper>
);

export default Title;
