import { styled } from 'shared/components/ihcl/styled';
import Cookie from 'js-cookie';
import throttle from 'just-throttle';
import PropTypes from 'prop-types';
import qs from 'qs';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

import {
  serverErrorsReceived,
  updateTalentProfileComplete,
} from 'shared/actions/talentProfileActions';
import sharedConstants from 'shared/constants/constants.json';
import { Button } from 'shared/components/ihcl/button';
import { StatefulInput } from 'shared/components/ihcl/input';
import { ToasterContainer, toaster } from 'shared/components/ihcl/toast';
import getFetchSignupSettings from 'shared/helpers/getFetchSignupSettings';
import {
  trackClick,
  trackNonHiringSignUpCompletion,
} from 'shared/helpers/tracking';

import { convertStateToAttributes } from '../../../helpers/talentProfileConversions';
import validateTalentProfile, {
  validateTalentProfileField,
} from '../../../helpers/validateTalentProfile';
import getGoogleClickID from '../../../helpers/getGoogleClickID';
import { useOnboardingStepContext } from '../providers/OnboardingStepProvider';
import { useOnboardingVariantContext } from '../providers/OnboardingVariantProvider';

import GoogleSignIn from './GoogleSignIn';

const EmailSignUpWrapper = styled('div', {
  marginTop: '24px',
  fontSize: '16px',
});

const EmailSignUpLink = styled('div', ({ $theme }) => ({
  color: $theme.colors.primary,
  cursor: 'pointer',
  display: 'inline-block',
  textDecoration: 'underline',
}));

export const ErrorText = styled('div', ({ $theme }) => ({
  color: $theme.colors.negative70,
  fontSize: '14px',
}));

export const FooterWrapper = styled('div', {
  fontSize: '14px',
});

export const SubmitFooterWrapper = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  fontSize: '14px',
});

export const Subtitle = styled('p', {
  marginTop: '20px',
  marginBottom: '30px',
  fontSize: '16px',
});

const ErrorRow = ({ errorKey, errors }) => {
  if (errorKey === 'email' || errorKey === 'google') {
    return null;
  }
  if (errors[errorKey].message) {
    return (
      <div className="alert alert-danger">
        {`${errorKey} ${errors[errorKey].message}.`}&nbsp; Please fix this error
        by going back through the previous screens.
      </div>
    );
  }
  return null;
};
ErrorRow.propTypes = {
  errorKey: PropTypes.string.isRequired,
  errors: PropTypes.object.isRequired,
};

// eslint-disable-next-line react/require-default-props
export const ErrorContainer = ({ errors }) => {
  let alerts = Object.keys(errors).map((key) => (
    <ErrorRow key={key} errorKey={key} errors={errors} />
  ));
  if (errors.user) {
    alerts = alerts.concat(
      Object.keys(errors.user).map((key) => (
        <ErrorRow key={key} errorKey={key} errors={errors.user} />
      ))
    );
  }
  alerts = alerts.filter((alert) => alert != null);

  if (alerts.length === 0) {
    return null;
  }

  return (
    <div className="flash-messages container-fluid">
      <Row>
        <Col className="top-buffer-visible-xs" sm={8} smOffset={2}>
          {alerts}
        </Col>
      </Row>
    </div>
  );
};
ErrorContainer.propTypes = {
  errors: PropTypes.object,
};
ErrorContainer.defaultProps = {
  errors: {},
};

export const SignInButton = () => (
  <Button
    kind="minimal"
    onClick={() => {
      window.location = '/users/sign_in';
    }}
  >
    Sign In?
  </Button>
);
SignInButton.propTypes = {};

export const getTitles = (intent) => {
  let title;
  let subtitle;

  switch (intent) {
    case 'advice':
      title = 'Join a community 100K+ nurses strong';
      subtitle =
        'Get custom advice from experienced nurses on all topics. Join now!';
      break;

    case 'education':
      title = 'Save for that vacation, not your CEUs. ';
      subtitle =
        'Sign up to access discounted accredited CE courses for nurses.';
      break;

    case 'mental_health':
      title = 'Nurse Mental Health';
      subtitle =
        'get access to all of our 100% free tools &' +
        ' services exclusively for nurses.';
      break;

    case 'network':
      title = 'Nurse Community';
      subtitle = 'get access to our community of nurses & much more.';
      break;

    case 'resume':
      title = 'Instantly generate your resume!';
      subtitle =
        'Answer a few questions, and let our Resume Wizard do the rest.';
      break;

    case 'salary':
      title = '60% of Americans believe nurses are underpaid.';
      subtitle =
        'Earn what you deserve. Sign up to get a personalized wage estimate.';
      break;

    default:
      console.error(`Unknown intent: ${intent}`);
  }

  return [title, subtitle];
};

// eslint-disable-next-line react/require-default-props
const OnboardingFormEmail = ({ dispatch, includeGoogleSignIn }) => {
  const {
    addTalentProfileKeys,
    completeStep,
    maybeGoToPrevious,
    localStepTalentProfile,
  } = useOnboardingStepContext();
  const { currentStep, intent, onboardVariant } = useOnboardingVariantContext();

  const location = useLocation();
  const urlQuery = qs.parse(location.search, { ignoreQueryPrefix: true });

  const formRef = useRef(null);
  const {
    clearErrors,
    handleSubmit,
    register,
    setError,
    formState: { errors },
  } = useForm({
    mode: 'onTouched',
    defaultValues: localStepTalentProfile,
  });
  const formErrors = {
    ...errors,
    ...errors.user,
  };
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const [displayEmailInput, setDisplayEmailInput] = useState(
    !includeGoogleSignIn
  );
  const [googleJwt, setGoogleJwt] = useState(null);

  useEffect(() => {
    addTalentProfileKeys(['email']);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const signUp = async (formData) => {
    const validationErrors = validateTalentProfile(
      formData,
      Object.keys(formData)
    );
    if (validationErrors.size > 0 && !googleJwt) {
      return;
    }

    const signUpUrl = '/users/sign_up/talent.json';
    const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    let profileAttributes = {
      onboard_variant: onboardVariant,
      signup_intent: intent,
      timezone_from_onboarding: browserTimezone,
    };

    if (urlQuery.source) {
      profileAttributes.source = urlQuery.source;
    }
    sharedConstants.PROFILE_TRACKING_PARAM_NAMES.forEach((utmParamName) => {
      if (urlQuery[utmParamName]) {
        profileAttributes[utmParamName] = urlQuery[utmParamName];
      }
    });
    if (urlQuery.ref) {
      profileAttributes.referrer_code = urlQuery.ref;
    }
    const gclid = Cookie.get('gclid') || getGoogleClickID(urlQuery);
    if (gclid) {
      profileAttributes.google_click_id = gclid;
    }

    const localOnboardingUpdates = convertStateToAttributes(
      localStepTalentProfile,
      Object.keys(localStepTalentProfile),
      formData
    );

    profileAttributes = {
      ...profileAttributes,
      ...localOnboardingUpdates,
      current_onboard_step: currentStep,
    };
    const body = {
      user: {
        ...formData,
        talent_profile_attributes: profileAttributes,
        remember_me: true,
      },
    };

    if (!formData || !formData.password) {
      body.gen_password = true;
    }
    if (googleJwt) {
      body.google = { credential: googleJwt };
    }
    setIsFormSubmitting(true);
    fetch(signUpUrl, getFetchSignupSettings(body))
      .then((data) => data.json())
      .then(async (json) => {
        setIsFormSubmitting(false);
        if (json && json.errors) {
          Object.keys(json.errors).forEach((key) => {
            if (key === 'user.email') {
              setError('email', {
                type: 'validationError',
                message: json.errors[key].join('; '),
              });
            } else {
              setError(key, {
                type: 'validationError',
                message: json.errors[key].join('; '),
              });
            }
          });
          dispatch(serverErrorsReceived(json));
        } else {
          trackClick('talent.sign_up', {
            Intent: intent,
          });
          trackNonHiringSignUpCompletion(profileAttributes);
          if (json.user_id && json.user_id.toString()) {
            mixpanel.alias(json.user_id.toString());
          } else {
            console.error(`cannot alias user_id: ${json.user_id}`);
          }
          mixpanel.register({ 'User Type': 'talent' });
          dispatch(updateTalentProfileComplete(json));
          dispatch(serverErrorsReceived({}));
          completeStep({});
        }
      })
      .catch((error) => {
        console.error(error);
        setIsFormSubmitting(false);
        const errorMsg =
          'Oops, there was a problem connecting ' +
          'to the server. Please check your internet connection ' +
          'and try again, or refresh the page.';
        toaster.negative(errorMsg);
      });
  };

  useEffect(() => {
    if (googleJwt) {
      clearErrors('email');
      signUp({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [googleJwt]);

  useEffect(() => {
    clearErrors('email');
    if (displayEmailInput) {
      setGoogleJwt(null);
      clearErrors('google');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayEmailInput]);

  const blurField = (evt, fieldName, fieldProps) => {
    fieldProps.onBlur(evt);
  };
  const changeField = (evt, fieldName, fieldProps) => {
    if (formErrors[fieldName]) {
      clearErrors(fieldName);
    }
    fieldProps.onChange(evt);
  };
  const emailProps = register('email', {
    validate: (value) => {
      if (!googleJwt) {
        return validateTalentProfileField('email', value);
      }
      return null;
    },
  });
  const submitHandler = handleSubmit(signUp);
  const throttledSubmitHandler = useCallback(
    throttle((evt) => submitHandler(evt), 1000, { leading: true }),
    []
  );
  const emailErrorMessage = formErrors.email && formErrors.email.message;
  const googleErrorMessage = formErrors.google && formErrors.google.message;
  const signInPrompt = emailErrorMessage === 'has already been taken' && (
    <SignInButton />
  );
  const emailInput = displayEmailInput ? (
    <>
      <StatefulInput
        caption={emailErrorMessage}
        collapseCaptionPadding
        initialState={{ value: localStepTalentProfile.email }}
        label="Email Address"
        type="email"
        inputMode="email"
        {...emailProps}
        onBlur={(evt) => blurField(evt, 'email', emailProps)}
        onChange={(evt) => changeField(evt, 'email', emailProps)}
        error={!!formErrors.email}
      />
      {signInPrompt}
    </>
  ) : (
    <>
      <GoogleSignIn setGoogleJwt={setGoogleJwt} isSignIn={false} />
      {(emailErrorMessage || googleErrorMessage) && (
        <ErrorText>{emailErrorMessage || googleErrorMessage}</ErrorText>
      )}
      {signInPrompt}
      <EmailSignUpWrapper>
        Or&nbsp;
        <EmailSignUpLink
          id="email-signup"
          onClick={() => {
            setDisplayEmailInput(true);
          }}
        >
          Sign up with email
        </EmailSignUpLink>
      </EmailSignUpWrapper>
    </>
  );

  const [title, subtitle] = getTitles(intent);
  return (
    <>
      <div>
        <ErrorContainer errors={errors} />
        <h1 className="title">{title}</h1>
        <Subtitle>{subtitle}</Subtitle>
        <form
          action="#"
          id="OnboardingFormEmail"
          onSubmit={(evt) => {
            evt.preventDefault();
            throttledSubmitHandler(evt);
          }}
          ref={formRef}
        >
          <ToasterContainer />
          {emailInput}
        </form>
      </div>
      <FooterWrapper>
        By signing up you agree to Incredible Health&apos;s&nbsp;
        <a
          href="https://www.incrediblehealth.com/terms.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          Terms of Service
        </a>
        &nbsp; and&nbsp;
        <a
          href="https://www.incrediblehealth.com/privacy.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy Policy
        </a>
      </FooterWrapper>
      <SubmitFooterWrapper>
        {currentStep > 1 ? (
          <Button
            type="button"
            kind="minimal"
            onClick={() => {
              if (displayEmailInput && includeGoogleSignIn) {
                setDisplayEmailInput(false);
              } else {
                maybeGoToPrevious();
              }
            }}
          >
            Back
          </Button>
        ) : (
          <span />
        )}
        <Button
          className="submit"
          form="OnboardingFormEmail"
          disabled={
            isFormSubmitting || (!googleJwt && Boolean(formErrors.email))
          }
          style={{
            marginTop: '15px',
            padding: '10px 24px',
          }}
        >
          Continue
        </Button>
      </SubmitFooterWrapper>
    </>
  );
};

OnboardingFormEmail.propTypes = {
  dispatch: PropTypes.func.isRequired,
  includeGoogleSignIn: PropTypes.bool,
};

OnboardingFormEmail.defaultProps = {
  includeGoogleSignIn: false,
};

export default connect()(OnboardingFormEmail);
