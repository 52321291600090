import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { styled, useStyletron, withStyle } from 'shared/components/ihcl/styled';

import { Button } from 'shared/components/ihcl/button';
import { ButtonSelect } from 'shared/components/ihcl/button_select';
import { ReturnIcon } from 'shared/components/ihcl/icon';
import { Input } from 'shared/components/ihcl/input';
import { Center } from 'shared/components/ihcl/positioning';
import {
  educationLevels as educationLevelsOrig,
  educationLevelsExcludingStudent,
  educationLevelsForTechs,
} from 'shared/constants/selectOptions';

import {
  Onboarding,
  OnboardingContext,
  OnboardingSubtitle,
  OnboardingTitle,
} from 'registration/containers/Onboarding';

const CustomCertInputWrapper = styled('div', ({ $theme }) => ({
  paddingTop: $theme.sizing.scale600,
  color: $theme.colors.gray,
}));

const IsolatedSteps = styled('div', () => ({
  minHeight: '150vh',
}));

const FirstIsolatedStep = styled('div', () => ({
  width: '100%',
}));

const IsolatedStep = withStyle(FirstIsolatedStep, () => ({
  paddingTop: '40px',
}));

const yesNoOptions = [
  { label: 'No', value: 'no' },
  { label: 'Yes', value: 'yes' },
];

const popularCertificationOptions = [
  'ACLS',
  'BLS',
  'CPR',
  'NRP',
  'PALS',
  'RN',
  'ONS/ONCC',
].map((cert) => ({ label: cert, value: cert }));

// eslint-disable-next-line react/require-default-props
const OnboardingCredentials = ({
  // eslint-disable-next-line react/require-default-props
  nextAction,
  // eslint-disable-next-line react/require-default-props
  previousAction,
  stepNumber,
  // eslint-disable-next-line react/require-default-props
  useEducationOptionsExcludingStudent,
}) => {
  const {
    onboardingData,
    profileType = 'nurse',
    setOnboardingData,
  } = useContext(OnboardingContext);
  const {
    acute_care_experience: acuteCareExperience,
    certifications = [],
    clinical_care_experience: clinicalCareExperience,
    education_level: educationLevelValue,
  } = onboardingData;
  const [, theme] = useStyletron();
  const [isAddingCertification, setIsAddingCertification] = useState(false);
  const [customCertificationText, setCustomCertificationText] = useState('');
  const [customCertificationOptions, setCustomCertificationOptions] = useState(
    []
  );
  const educationLevelRef = useRef(null);
  const recentHospitalExpRef = useRef(null);
  const clinicalCareExpRef = useRef(null);
  const certificationsRef = useRef(null);
  let showAcuteCareExperienceQuestions = true;
  let educationLevels = educationLevelsOrig;
  if (useEducationOptionsExcludingStudent) {
    educationLevels = educationLevelsExcludingStudent;
  }
  if (profileType === 'tech') {
    educationLevels = educationLevelsForTechs;
    showAcuteCareExperienceQuestions = false;
  }
  const certificationOptions = popularCertificationOptions.concat(
    customCertificationOptions
  );
  const isNextDisabled =
    !educationLevelValue ||
    (showAcuteCareExperienceQuestions && !acuteCareExperience) ||
    (showAcuteCareExperienceQuestions &&
      acuteCareExperience === 'no' &&
      !clinicalCareExperience) ||
    !certifications ||
    certifications.length === 0;
  const addCustomCertification = () => {
    if (
      !customCertificationOptions.some(
        (certificationOption) =>
          certificationOption.label === customCertificationText
      )
    ) {
      setCustomCertificationOptions([
        ...customCertificationOptions,
        {
          label: customCertificationText,
          value: customCertificationText,
        },
      ]);
    }
    if (!certifications.includes(customCertificationText)) {
      setOnboardingData({
        ...onboardingData,
        certifications: [...certifications, customCertificationText],
      });
    }
    setCustomCertificationText('');
    setIsAddingCertification(false);
  };

  useEffect(() => {
    if (
      educationLevelValue &&
      !(acuteCareExperience || clinicalCareExperience) &&
      showAcuteCareExperienceQuestions
    ) {
      recentHospitalExpRef.current.scrollIntoView({ behavior: 'smooth' });
      return;
    }
    if (
      acuteCareExperience &&
      !(certifications.length || clinicalCareExperience)
    ) {
      if (acuteCareExperience === 'no') {
        clinicalCareExpRef.current.scrollIntoView({ behavior: 'smooth' });
        return;
      }
      certificationsRef.current.scrollIntoView({ behavior: 'smooth' });
      return;
    }
    if (
      (acuteCareExperience === 'yes' || clinicalCareExperience) &&
      !certifications.length
    ) {
      certificationsRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [
    educationLevelValue,
    acuteCareExperience,
    clinicalCareExperience,
    certifications.length,
  ]);

  return (
    <Onboarding
      currentStep={stepNumber}
      isNextDisabled={isNextDisabled}
      nextAction={nextAction}
      previousAction={previousAction}
      stepKeys={[
        'acute_care_experience',
        'certifications',
        'clinical_care_experience',
        'education_level',
      ]}
    >
      <Center>
        <OnboardingTitle>Show off your credentials!</OnboardingTitle>
        <IsolatedSteps>
          <FirstIsolatedStep ref={educationLevelRef}>
            <OnboardingSubtitle>
              What&apos;s your highest education level?
            </OnboardingSubtitle>
            <ButtonSelect
              orientation="vertical"
              options={educationLevels}
              onChange={(value) => {
                setOnboardingData({
                  ...onboardingData,
                  education_level: value,
                });
              }}
              selectedOptions={educationLevels.filter(
                (option) => option.value === educationLevelValue
              )}
            />
          </FirstIsolatedStep>
          {showAcuteCareExperienceQuestions && educationLevelValue && (
            <IsolatedStep ref={recentHospitalExpRef}>
              <OnboardingSubtitle>
                Have you worked in a hospital within the last&nbsp;year?
              </OnboardingSubtitle>
              <ButtonSelect
                radioSelect
                orientation="horizontal"
                options={yesNoOptions}
                onChange={(value) => {
                  setOnboardingData({
                    ...onboardingData,
                    acute_care_experience: value,
                  });
                }}
                selectedOptions={yesNoOptions.filter(
                  (option) => option.value === acuteCareExperience
                )}
              />
            </IsolatedStep>
          )}
          {showAcuteCareExperienceQuestions && acuteCareExperience === 'no' && (
            <IsolatedStep ref={clinicalCareExpRef}>
              <OnboardingSubtitle>
                Do you have 1+ years of experience in a clinical care setting?
              </OnboardingSubtitle>
              <ButtonSelect
                radioSelect
                orientation="horizontal"
                options={yesNoOptions}
                onChange={(value) => {
                  setOnboardingData({
                    ...onboardingData,
                    clinical_care_experience: value,
                  });
                }}
                selectedOptions={yesNoOptions.filter(
                  (option) => option.value === clinicalCareExperience
                )}
              />
            </IsolatedStep>
          )}
          {(acuteCareExperience === 'yes' ||
            clinicalCareExperience ||
            (!showAcuteCareExperienceQuestions && educationLevelValue)) && (
            <IsolatedStep ref={certificationsRef}>
              <OnboardingSubtitle>
                What certifications do you have?
              </OnboardingSubtitle>
              <OnboardingSubtitle>
                Please select all that apply
              </OnboardingSubtitle>
              <ButtonSelect
                multiSelect
                orientation="horizontal"
                options={certificationOptions}
                onChange={(value, option, remove) => {
                  let newCertifications = certifications
                    ? [...certifications]
                    : [];
                  if (remove) {
                    newCertifications = newCertifications.filter(
                      (certification) => certification !== value
                    );
                  } else {
                    newCertifications.push(value);
                  }
                  setOnboardingData({
                    ...onboardingData,
                    certifications: newCertifications,
                  });
                }}
                selectedOptions={certificationOptions.filter(
                  (option) =>
                    certifications && certifications.includes(option.value)
                )}
                padding="minimal"
              />
              {!isAddingCertification && (
                <Button
                  kind="minimal"
                  onClick={() => {
                    setIsAddingCertification(true);
                  }}
                >
                  + Add another certification
                </Button>
              )}
              {isAddingCertification && (
                <CustomCertInputWrapper>
                  <Input
                    autoFocus
                    enterkeyhint="done"
                    label="Enter your certification"
                    value={customCertificationText}
                    endEnhancer={
                      <ReturnIcon
                        onClick={addCustomCertification}
                        color={theme.colors.gray}
                      />
                    }
                    onKeyDown={(evt) => {
                      if (evt.keyCode === 13) {
                        if (!customCertificationText) {
                          return;
                        }
                        addCustomCertification();
                      }
                    }}
                    onChange={(evt) =>
                      setCustomCertificationText(evt.currentTarget.value)
                    }
                  />
                </CustomCertInputWrapper>
              )}
            </IsolatedStep>
          )}
        </IsolatedSteps>
      </Center>
    </Onboarding>
  );
};
OnboardingCredentials.propTypes = {
  nextAction: PropTypes.string,
  previousAction: PropTypes.string,
  stepNumber: PropTypes.number.isRequired,
  useEducationOptionsExcludingStudent: PropTypes.bool,
};
OnboardingCredentials.defaultProps = {
  nextAction: null,
  previousAction: null,
  useEducationOptionsExcludingStudent: false,
};

export default OnboardingCredentials;
