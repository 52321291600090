import React from 'react';
import { Button } from 'shared/components/ihcl/button';
import { FloatModal } from 'shared/components/ihcl/floatModal';
import { Text } from 'shared/components/ihcl/text';

import { LucideIcon } from 'shared/components/ihcl/lucideIcon';
import { TextButton } from 'shared/components/ihcl/patterns';
import { Screening } from 'talent/types';
import ReservedTimeWindow from './ReservedTimeWindow';
import { BoldText, ContentWrapper, HeaderWrapper } from './styledComponents';

const ConfirmationModal = ({
  isOpen,
  closeModal,
  screening,
  handleUpdateContactInfo,
}: {
  isOpen: boolean;
  closeModal: (eventName: string) => void;
  screening: Screening;
  handleUpdateContactInfo: () => void;
}): JSX.Element => (
  <FloatModal
    isOpen={isOpen}
    innerPaddingBottom="40px"
    innerPaddingTop="24px"
    innerPaddingRight="24px"
    onClose={() => closeModal('talent.screening.confirmation.close')}
  >
    <ContentWrapper>
      <HeaderWrapper>
        <Text variant="HeadingSmall">
          We&apos;re excited to get to know you better!
        </Text>
      </HeaderWrapper>
      <ReservedTimeWindow screening={screening} />
      <Text variant="ParagraphSmall" color="grayBodyCopy">
        We have <BoldText>{screening.email}</BoldText> and{' '}
        <BoldText>{screening.phone.text}</BoldText> as your contact details. If
        that is incorrect, please{' '}
        <TextButton
          onClick={() => handleUpdateContactInfo()}
          data-testid="update-contact-info"
        >
          <Text variant="ParagraphSmall" color="grayBodyCopy">
            <u>update</u>
          </Text>
        </TextButton>{' '}
        us.
      </Text>
      <Button
        color="alternate"
        kind="primary"
        onClick={() => closeModal('talent.screening.confirmation.close')}
        fullWidth
        startEnhancer={<LucideIcon name="Calendar" />}
        data-testid="confirmation-close"
      >
        Close
      </Button>
    </ContentWrapper>
  </FloatModal>
);

export default ConfirmationModal;
