import React, { ReactNode } from 'react';
import { styled } from 'shared/components/ihcl/styled';

import testimonialBg from 'images/testimonial-bg.webp';
import { LucideIcon } from 'shared/components/ihcl/lucideIcon';
import { Stack } from 'shared/components/ihcl/patterns';
import { Text } from 'shared/components/ihcl/text';
import { getShortTimezone } from 'shared/helpers/timezones';

import { Screening } from 'talent/types';
import { formattedDateValues } from './helpers';

const Window = styled('div', ({ $theme }) => ({
  paddingTop: $theme.sizing.unit16,
  paddingRight: $theme.sizing.unit20,
  paddingLeft: $theme.sizing.unit20,
  paddingBottom: $theme.sizing.unit24,
  borderRadius: $theme.sizing.unit16,
  borderStyle: 'solid',
  borderColor: $theme.colors.accent60,
  borderWidth: '1px',
  boxShadow: '0px 4px 16px 0px #591B7133',
  display: 'flex',
  flexDirection: 'column',
  gap: $theme.sizing.unit12,
}));

const TicketShadow = styled('div', {
  filter: 'drop-shadow(0px 1px 4px #1d142a4d)',
});

const Ticket = styled('div', ({ $theme }) => ({
  background: `radial-gradient(50.01% 50% at 50% 50%, rgba(255, 255, 255, 0.90) 70%, ${$theme.colors.grayBackground} 200%), url(${testimonialBg}) lightgray 50% / cover no-repeat`,
  borderRadius: $theme.sizing.unit8,
  padding: $theme.sizing.unit20,
  display: 'flex',
  flexDirection: 'column',
  gap: $theme.sizing.unit20,
  '--r': '8px' /* cut size */,
  position: 'relative',
  mask: 'radial-gradient(var(--r) at var(--r), #0000 calc(100% - 1px), #000) calc(-1 * var(--r)) 25px',
}));

const TimeAndDate = styled('div', ({ $theme }) => ({
  display: 'flex',
  gap: $theme.sizing.unit16,
  justifyContent: 'space-between',
  paddingRight: $theme.sizing.unit20,
}));

const TimeAndDateHeading = styled(Text, ({ $theme }) => ({
  color: $theme.colors.grayLabel,
  display: 'flex',
  gap: $theme.sizing.unit4,
  alignItems: 'end',
}));

function ReservedTimeWindow({
  children = null,
  screening,
}: {
  children?: ReactNode;
  screening: Screening;
}) {
  const { monthDay, weekday, startTime, endTime } = formattedDateValues(
    new Date(screening.starts_at),
    {
      month: 'short',
      day: 'numeric',
      weekday: 'long',
    }
  );

  return (
    <Window>
      <Text variant="LabelSmallBold">Your reserved time window</Text>
      <TicketShadow>
        <Ticket>
          <TimeAndDate>
            <Stack $flexDirection="column" $gapSizing="unit8">
              <TimeAndDateHeading variant="LabelXSmall">
                <LucideIcon name="Clock" size="unit16" />
                <div>Time ({getShortTimezone()})</div>
              </TimeAndDateHeading>
              <Text variant="LabelLargeBold">
                <div>{startTime} -</div>
                <div>{endTime}</div>
              </Text>
            </Stack>
            <Stack $flexDirection="column" $gapSizing="unit8">
              <TimeAndDateHeading variant="LabelXSmall">
                <LucideIcon name="Calendar" size="unit16" />
                <span>Date</span>
              </TimeAndDateHeading>
              <Text variant="LabelLarge">
                <div>{monthDay}</div>
                <div>{weekday}</div>
              </Text>
            </Stack>
          </TimeAndDate>
          <Text variant="LabelXSmall" color="grayLabel">
            * We will call within this 30-minute window
          </Text>
        </Ticket>
      </TicketShadow>
      {children}
    </Window>
  );
}

export default ReservedTimeWindow;
