import { Set } from 'immutable';
import { combineReducers } from 'redux';

import serverErrors from './serverErrors';
import talentProfile from './talentProfile';
import techProfile from './techProfile';
import talentProfileCache from './talentProfileCache';
import uiState from './uiState';

export default combineReducers({
  careSettings: (state) => state || [],
  employerProfile: (state) => state || null,
  featureFlags: (state) => state || Set(),
  nurse_specialty_descriptions: (state) => state || [],
  nurse_specialty_skills: (state) => state || [],
  states: (state) => state || {},
  stateOptions: (state) => state || {},
  talent_profile: talentProfile,
  tech_profile: techProfile,
  talentProfileCache,
  serverErrors,
  uiState,
});

export const getEmployerProfile = (state) => state.employerProfile;
