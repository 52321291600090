import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { styled, useStyletron, withStyle } from 'shared/components/ihcl/styled';
import { Transition } from 'react-transition-group';

import { noYesOptions } from 'shared/constants/selectOptions';
import { ButtonSelect } from 'shared/components/ihcl/button_select';
import { Input } from 'shared/components/ihcl/input';
import { Tag } from 'shared/components/ihcl/tag';
import { Center } from 'shared/components/ihcl/positioning';

import {
  Onboarding,
  OnboardingContext,
  OnboardingTitle as OnboardingTitleBase,
  OnboardingSubtitle,
} from 'registration/containers/Onboarding';

import isReactivationFlow from '../../helpers/isReactivationFlow';

const SPECIALTY_YEARS_MIN = 1;
const SPECIALTY_YEARS_MAX = 99;

const yearInputMargin = '16px';
const yearInputWidth = '80px';
const specialtyRowHeight = '50px';

const OnboardingTitle = withStyle(
  OnboardingTitleBase,
  ({ $additionalTopSpacing = false }) => ({
    marginTop: $additionalTopSpacing ? '40px' : '64px',
  })
);

const SpecialtyRow = styled('div', {
  height: specialtyRowHeight,
  width: '100%',
  position: 'relative',
  overflow: 'hidden',
});

const SpecialtyButtonWrapper = styled('div', ({ $isFullWidth }) => ({
  display: 'flex',
  alignItems: 'center',
  height: specialtyRowHeight,
  width: $isFullWidth
    ? '100%'
    : `calc(100% - ${yearInputWidth} - ${yearInputMargin})`,
  position: 'absolute',
  transition: 'transform 200ms',
}));

const SpecialtyDetails = styled('div', ({ $isVisible }) => ({
  display: 'inline-block',
  height: specialtyRowHeight,
  width: yearInputWidth,
  right: 0,
  top: '7px',
  position: 'absolute',
  transition: 'transform 200ms',
  transform: $isVisible
    ? `translateX(calc(100% - ${yearInputWidth}))`
    : 'translateX(0)',
}));

const isValidSpecialtyYears = (years, recentGrad) =>
  years === undefined ||
  years === '' ||
  (SPECIALTY_YEARS_MIN <= years && years <= SPECIALTY_YEARS_MAX) ||
  (recentGrad && years === 0);

const VerifyHasWorkExperience = ({
  history,
  nextActionForRecentGrads,
  setHasWorkExperience,
}) => (
  <>
    <OnboardingTitle>
      Have you worked as an RN after graduation?
    </OnboardingTitle>
    <ButtonSelect
      options={noYesOptions}
      orientation="horizontal"
      onChange={(optionValue) => {
        if (!optionValue) {
          if (history && history.push) {
            history.push(nextActionForRecentGrads);
          } else {
            window.mixpanel.track(
              'No history.push available, falling-back to window.location'
            );
            window.location = nextActionForRecentGrads;
          }
          return;
        }
        setHasWorkExperience(optionValue);
      }}
    />
  </>
);

VerifyHasWorkExperience.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  nextActionForRecentGrads: PropTypes.string.isRequired,
  setHasWorkExperience: PropTypes.func.isRequired,
};

// eslint-disable-next-line react/require-default-props
const OnboardingSpecialtiesList = ({
  hideRecentGradYearEntry = false,
  history = {
    push: null,
  },
  isReactivation = false,
  nextAction = null,
  nextActionForRecentGrads = null,
  previousAction = null,
  stepNumber,
}) => {
  const {
    onboardingBasePath,
    onboardingData,
    onboardingOptions,
    onboardingUpdates,
    setOnboardingData,
  } = useContext(OnboardingContext);
  const [, theme] = useStyletron();
  const [allowNoSpecialties, setAllowNoSpecialties] = useState(false);
  const [specialtyYearsValid, setSpecialtyYearsValid] = useState({});
  const [recentGradHasWorkExperience, setRecentGradHasWorkExperience] =
    useState(null);
  const updateRecentGradFields = () => {
    if (recentGradHasWorkExperience) {
      const newNurseSpecialties = new Map();
      onboardingData.nurseSpecialties.forEach((years, id) => {
        newNurseSpecialties.set(id, years || 1);
      });
      setOnboardingData({
        ...onboardingData,
        nurseSpecialties: newNurseSpecialties,
        recent_grad: false,
      });
    }
  };
  const wrappedSetRecentGradHasWorkExperience = (
    newRecentGradHasWorkExperience
  ) => {
    // When setting recentGradHasWorkExperience to true, update the related fields
    if (!recentGradHasWorkExperience && newRecentGradHasWorkExperience) {
      updateRecentGradFields();
    }
    setRecentGradHasWorkExperience(newRecentGradHasWorkExperience);
  };

  if (!onboardingData || Object.keys(onboardingData).length < 1) {
    return null;
  }

  const { nurse_specialty_descriptions: nurseSpecialtyDescriptions } =
    onboardingOptions;
  let { nurseSpecialties } = onboardingData;
  /* eslint-disable @typescript-eslint/naming-convention */
  const { recent_grad: recentGradOD } = onboardingData;
  const { recent_grad: recentGradOU } = onboardingUpdates;
  const recentGrad = recentGradOD || recentGradOU;
  if (nurseSpecialties.size === 0 && !allowNoSpecialties) {
    nurseSpecialties = new Map().set(undefined, undefined);
  }

  const specialtyNeedsId = [...nurseSpecialties].some(
    ([id]) => id === undefined
  );

  const specialtyNeedsYears = [...nurseSpecialties].some(
    ([, years]) => years === undefined || years === ''
  );

  const specialtyInvalidYears = [...nurseSpecialties].some(
    ([, years]) =>
      !isValidSpecialtyYears(years, recentGrad && hideRecentGradYearEntry)
  );

  const specialtyNeedsValues =
    specialtyNeedsId || specialtyNeedsYears || specialtyInvalidYears;

  const nurseSpecialtiesArr = [...nurseSpecialties];

  const shouldUpdateRecentGrad = isReactivationFlow(onboardingBasePath);
  const title = isReactivationFlow(onboardingBasePath) ? (
    <OnboardingTitle>
      {recentGradHasWorkExperience
        ? 'What specialties have you worked in?'
        : 'Have you gained any new experience?'}
    </OnboardingTitle>
  ) : (
    <span>
      <OnboardingTitle>
        {recentGrad && hideRecentGradYearEntry
          ? 'Select specialties you want to work in!'
          : 'Select specialties you have experience in!'}
      </OnboardingTitle>
      <OnboardingSubtitle>
        {recentGrad && hideRecentGradYearEntry
          ? 'Although not all hospitals have these open for recent graduates, this still helps them place you.'
          : 'It’s ok to estimate your years of experience. More specialties = more job matches!'}
      </OnboardingSubtitle>
    </span>
  );

  let contents;
  if (isReactivation && recentGrad && recentGradHasWorkExperience === null) {
    contents = (
      <VerifyHasWorkExperience
        history={history}
        nextActionForRecentGrads={nextActionForRecentGrads}
        setHasWorkExperience={wrappedSetRecentGradHasWorkExperience}
      />
    );
  } else {
    contents = (
      <Center>
        {title}
        {nurseSpecialtyDescriptions.map((nsd) => (
          <SpecialtyRow key={nsd.id}>
            <Transition in={!(nurseSpecialties.get(nsd.id) >= 0)} timeout={200}>
              {(state) => (
                <SpecialtyButtonWrapper
                  $isFullWidth={
                    ['entering', 'entered'].includes(state) ||
                    recentGrad ||
                    recentGradHasWorkExperience
                  }
                >
                  <Tag
                    closeable={false}
                    role="option"
                    aria-selected={nurseSpecialties.get(nsd.id) >= 0}
                    variant="solid"
                    size="medium"
                    kind={
                      nurseSpecialties.get(nsd.id) >= 0 ? 'custom' : 'primary'
                    }
                    color={theme.colors.primary}
                    $textColor={
                      nurseSpecialties.get(nsd.id) >= 0
                        ? 'white'
                        : theme.colors.primary
                    }
                    $containerWidth
                    onClick={() => {
                      const newNurseSpecialties = new Map();
                      let isRecentGrad = onboardingData.recent_grad;
                      nurseSpecialties.forEach((years, id) => {
                        if (id && id !== nsd.id) {
                          newNurseSpecialties.set(id, years);
                        }
                      });
                      if (!(nurseSpecialties.get(nsd.id) >= 0)) {
                        if (recentGrad || recentGradHasWorkExperience) {
                          if (isReactivation) {
                            newNurseSpecialties.set(nsd.id, 1);
                            if (shouldUpdateRecentGrad) {
                              isRecentGrad = false;
                            }
                          } else {
                            newNurseSpecialties.set(nsd.id, 0);
                          }
                        } else {
                          newNurseSpecialties.set(nsd.id, '');
                        }
                      }
                      setOnboardingData({
                        ...onboardingData,
                        nurseSpecialties: newNurseSpecialties,
                        recent_grad: isRecentGrad,
                      });
                    }}
                  >
                    {nsd.description}
                  </Tag>
                </SpecialtyButtonWrapper>
              )}
            </Transition>
            <Transition in={nurseSpecialties.get(nsd.id) >= 0} timeout={200}>
              {(state) =>
                (recentGrad || recentGradHasWorkExperience) &&
                hideRecentGradYearEntry ? null : (
                  <SpecialtyDetails
                    $isVisible={['entering', 'entered'].includes(state)}
                  >
                    {['entering', 'entered'].includes(state) && (
                      <Input
                        autoFocus
                        size="tiny"
                        label="Years"
                        tinyLabel="yrs"
                        type="number"
                        inputMode="decimal"
                        min="{SPECIALTY_YEARS_MIN}"
                        max="{SPECIALTY_YEARS_MAX}"
                        step="1"
                        value={nurseSpecialties.get(nsd.id)}
                        error={
                          nsd.id in specialtyYearsValid &&
                          !specialtyYearsValid[nsd.id]
                        }
                        onChange={(evt) => {
                          const newNurseSpecialties = new Map(nurseSpecialties);
                          newNurseSpecialties.set(
                            nsd.id,
                            evt.currentTarget.value
                          );
                          const newOnboardingData = {
                            ...onboardingData,
                            nurseSpecialties: newNurseSpecialties,
                          };
                          if (
                            recentGrad &&
                            shouldUpdateRecentGrad &&
                            isValidSpecialtyYears(evt.currentTarget.value)
                          ) {
                            newOnboardingData.recent_grad = false;
                            newOnboardingData.years_experience =
                              evt.currentTarget.value;
                          }
                          setOnboardingData(newOnboardingData);
                          setSpecialtyYearsValid({
                            ...specialtyYearsValid,
                            [nsd.id]: isValidSpecialtyYears(
                              evt.currentTarget.value
                            ),
                          });
                        }}
                        style={{
                          maxWidth: yearInputWidth,
                          minWidth: yearInputWidth,
                        }}
                      />
                    )}
                  </SpecialtyDetails>
                )
              }
            </Transition>
          </SpecialtyRow>
        ))}
        {(nurseSpecialties.size === 0 ||
          (nurseSpecialties.size === 1 &&
            nurseSpecialtiesArr &&
            nurseSpecialtiesArr[0][0] === undefined)) && (
          <SpecialtyRow style={{ marginTop: '16px' }}>
            <SpecialtyButtonWrapper $isFullWidth>
              <Tag
                closeable={false}
                variant="solid"
                size="medium"
                kind="custom"
                color={
                  allowNoSpecialties ? theme.colors.primary : 'transparent'
                }
                $textColor={allowNoSpecialties ? 'white' : theme.colors.primary}
                $containerWidth
                onClick={() => {
                  if (allowNoSpecialties) {
                    setAllowNoSpecialties(false);
                  } else {
                    setAllowNoSpecialties(true);
                    if (shouldUpdateRecentGrad) {
                      setOnboardingData({
                        ...onboardingData,
                        recent_grad: true,
                        years_experience: null,
                      });
                    }
                  }
                }}
              >
                No specialties yet
              </Tag>
            </SpecialtyButtonWrapper>
          </SpecialtyRow>
        )}
      </Center>
    );
  }

  return (
    <Onboarding
      currentStep={stepNumber}
      isNextDisabled={Boolean(
        specialtyNeedsValues ||
          (isReactivation &&
            onboardingData.recent_grad &&
            recentGradHasWorkExperience === null)
      )}
      nextAction={
        recentGrad && nextActionForRecentGrads
          ? nextActionForRecentGrads
          : nextAction
      }
      previousAction={previousAction}
      stepKeys={['nurseSpecialties', 'recent_grad', 'years_experience']}
    >
      {contents}
    </Onboarding>
  );
};
OnboardingSpecialtiesList.propTypes = {
  hideRecentGradYearEntry: PropTypes.bool,
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  isReactivation: PropTypes.bool,
  nextAction: PropTypes.string,
  nextActionForRecentGrads: PropTypes.string,
  previousAction: PropTypes.string,
  stepNumber: PropTypes.number.isRequired,
};

export default OnboardingSpecialtiesList;
