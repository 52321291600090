import React, { useState } from 'react';
import { Button } from 'shared/components/ihcl/button';
import { FloatModal } from 'shared/components/ihcl/floatModal';
import { styled } from 'shared/components/ihcl/styled';
import { Text } from 'shared/components/ihcl/text';
import { trackClick } from 'shared/helpers/tracking';

import advocateImagePath from 'images/personal-talent-advocate.svg';
import { LucideIcon } from 'shared/components/ihcl/lucideIcon';
import { TextButton } from 'shared/components/ihcl/patterns';
import { BoldText, ContentWrapper, HeaderWrapper } from './styledComponents';

const Image = styled('img', () => ({
  width: '200px',
}));

const WhyNecessaryText = styled(Text, ({ $theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: $theme.sizing.unit8,
}));

const WhyNecessaryModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: Function;
}): JSX.Element => (
  <FloatModal
    isOpen={isOpen}
    innerPaddingBottom="40px"
    innerPaddingTop="24px"
    innerPaddingRight="24px"
    onClose={() => onClose()}
  >
    <ContentWrapper>
      <HeaderWrapper>
        <Text variant="HeadingSmall">Why is this call necessary?</Text>
      </HeaderWrapper>
      <Image src={advocateImagePath} />
      <Text variant="ParagraphSmall" color="grayBodyCopy">
        This short <BoldText>10 minute</BoldText> introductory call is an
        opportunity for us to get to know you better and answer any questions
        you might have about the{' '}
        <BoldText>roles you’ve been matched to</BoldText>.
      </Text>
      <Button
        color="alternate"
        kind="primary"
        onClick={() => onClose()}
        fullWidth
        data-testid="why-necessary-close"
      >
        Close
      </Button>
    </ContentWrapper>
  </FloatModal>
);

const WhyNecessary = () => {
  const [showWhyNecessaryModal, setShowWhyNecessaryModal] = useState(false);

  return (
    <>
      <TextButton
        onClick={() => {
          trackClick('talent.screening.why_necessary.open');
          setShowWhyNecessaryModal(true);
        }}
      >
        <WhyNecessaryText variant="LabelSmall" color="accent">
          Why is this call necessary? <LucideIcon name="CircleHelp" />
        </WhyNecessaryText>
      </TextButton>
      <WhyNecessaryModal
        isOpen={showWhyNecessaryModal}
        onClose={() => {
          trackClick('talent.screening.why_necessary.close');
          setShowWhyNecessaryModal(false);
        }}
      />
    </>
  );
};

export default WhyNecessary;
