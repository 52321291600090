import compare from 'just-compare';
import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { AddLocation } from 'registration/components/new_pages/OnboardingCurrentLocation/AddLocation';
import { ExistingLocation } from 'registration/components/new_pages/OnboardingCurrentLocation/ExistingLocation';
import {
  Onboarding,
  OnboardingContext,
} from 'registration/containers/Onboarding';
import { getShouldPreselectLocations } from 'registration/helpers/experiment/xena';
import getNurseSpecialtyIds from 'registration/helpers/getNurseSpecialtyIds';
import isReactivationFlow from 'registration/helpers/isReactivationFlow';
import { OnboardingData } from 'registration/helpers/types';
import { pathToVariant } from 'shared/helpers/activateABTest';

export const OnboardingCurrentLocation = ({
  stepNumber,
  autoProgress = false,
  nextAction = null,
  previousAction = null,
}: {
  autoProgress?: boolean;
  nextAction?: string;
  previousAction?: string;
  stepNumber: number;
}) => {
  const {
    history,
    onboardingBasePath,
    onboardingData,
    onboardingUpdates,
    setOnboardingData,
  } = useContext<{
    history: any;
    onboardingBasePath: string;
    onboardingData: OnboardingData;
    onboardingUpdates: any;
    priorStep: number;
    setOnboardingData: Dispatch<SetStateAction<any>>;
    setOnboardingUpdates: Dispatch<SetStateAction<any>>;
    totalSteps: number;
  }>(OnboardingContext);

  const [currentLocation, setCurrentLocation] = useState(
    onboardingData.currentLocation
  );
  const [openToRelocation, setOpenToRelocation] = useState(
    onboardingData.openToRelocation
  );
  const [otherDesiredLocations, setOtherDesiredLocations] = useState([]);
  const [stillAtCurrentLocation, setStillAtCurrentLocation] = useState(null);
  const [shouldPreselectOtherLocations, setShouldPreselectOtherLocations] =
    useState(false);

  const variant = pathToVariant(onboardingBasePath);

  useEffect(() => {
    getShouldPreselectLocations(variant).then((value) =>
      setShouldPreselectOtherLocations(value)
    );
  }, [variant]);

  useEffect(() => {
    if (
      (currentLocation &&
        !compare(onboardingData.currentLocation, currentLocation)) ||
      (openToRelocation !== null &&
        onboardingData.openToRelocation !== openToRelocation) ||
      // onboardingData.desiredLocations includes the `currentLocation` so the
      // + 1 here represents that.
      (otherDesiredLocations.length &&
        onboardingData.desiredLocations.length !==
          otherDesiredLocations.length + 1)
    ) {
      setOnboardingData({
        ...onboardingData,
        currentLocation,
        desiredLocations: [currentLocation, ...otherDesiredLocations],
        openToRelocation,
      });
    }
    if (
      currentLocation &&
      currentLocation.zip_code &&
      currentLocation.zip_code !== currentLocation.zipCode
    ) {
      setCurrentLocation({
        ...currentLocation,
        zipCode: currentLocation.zip_code,
      });
    }
  }, [
    currentLocation,
    onboardingData,
    openToRelocation,
    otherDesiredLocations,
    setCurrentLocation,
    setOnboardingData,
    setOtherDesiredLocations,
  ]);

  const { nurseSpecialties } = onboardingData;
  const { nurse_specialties_attributes: nurseSpecialtiesAttributes } =
    onboardingUpdates;
  let back = false;
  if (history.location.state && history.location.state.back) {
    ({ back } = history.location.state);
  }
  const page =
    !isReactivationFlow(onboardingBasePath) ||
    !currentLocation ||
    stillAtCurrentLocation === false ? (
      <AddLocation
        currentLocation={currentLocation}
        setCurrentLocation={setCurrentLocation}
        openToRelocation={openToRelocation}
        setOpenToRelocation={setOpenToRelocation}
        isRecentGrad={Boolean(onboardingData.recent_grad)}
        onboardingInformation={{
          nurseSpecialties: [...getNurseSpecialtyIds(nurseSpecialties)].concat(
            (nurseSpecialtiesAttributes || []).map(
              (nsa) => nsa.nurse_specialty_description_id
            )
          ),
        }}
        otherDesiredLocations={otherDesiredLocations}
        setOtherDesiredLocations={setOtherDesiredLocations}
        shouldPreselectOtherLocations={shouldPreselectOtherLocations}
        variant={variant}
      />
    ) : (
      <ExistingLocation
        currentLocation={currentLocation}
        setCurrentLocation={setCurrentLocation}
        openToRelocation={openToRelocation}
        setOpenToRelocation={setOpenToRelocation}
        isRecentGrad={Boolean(onboardingData.recent_grad)}
        setStillAtCurrentLocation={setStillAtCurrentLocation}
        stillAtCurrentLocation={stillAtCurrentLocation}
      />
    );
  return (
    <Onboarding
      autoProgress={autoProgress && !back && !shouldPreselectOtherLocations}
      currentStep={stepNumber}
      isNextDisabled={
        !currentLocation ||
        openToRelocation === undefined ||
        (isReactivationFlow(onboardingBasePath) &&
          stillAtCurrentLocation === null)
      }
      nextAction={nextAction}
      previousAction={previousAction}
      stepKeys={['currentLocation', 'openToRelocation', 'desiredLocations']}
    >
      {page}
    </Onboarding>
  );
};
