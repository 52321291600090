import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { parsePageQuery } from 'shared/helpers/query';
import { usePrevious } from 'shared/helpers/hooks';
import ScheduleScreening from './ScheduleScreening';
import OnboardingEducation from '../components/new_pages/OnboardingEducation';
import OnboardingEmail from '../components/new_pages/OnboardingEmailDelight';
import OnboardingName from '../components/new_pages/OnboardingName';
import OnboardingPassword from '../components/new_pages/OnboardingPassword';
import OnboardingPermanentJob from '../components/new_pages/OnboardingPermanentJob';
import OnboardingPhoneSimple from '../components/new_pages/OnboardingPhoneSimple';
import OnboardingTechCredentials from '../components/new_pages/OnboardingTechCredentials';
import OnboardingTechCertifications from '../components/new_pages/OnboardingTechCertifications';
import OnboardingTechRoles from '../components/new_pages/OnboardingTechRoles';
import OnboardingTimeline from '../components/new_pages/OnboardingTimelineIsolatedQuestions';
import {
  augmentOptions,
  getAugmentedOnboardingData,
} from '../helpers/talentProfileConversions';
import { getTechProfile } from '../selectors';
import { OnboardingContext } from './Onboarding';
import OnboardingCurrentLocation from '../components/new_pages/OnboardingCurrentLocation';
import OnboardingResume from '../components/new_pages/OnboardingResume';
import OnboardingShiftEmployment from '../components/new_pages/OnboardingShiftEmployment';
import OnboardingLocationPersonalized from '../components/new_pages/OnboardingLocationPersonalized';

const PASSWORD_STEP = 7;
const PHONE_STEP = 8;
const liteProfileSkippedSteps = [PASSWORD_STEP];

const validStepNames = ['learn', 'schedule'];

export const setUrl = (currentOnboardStep, history, path, locPathname) => {
  if (locPathname) {
    // Don't set the step if we're on a location with a valid step name
    const pathArr = locPathname.split(`${path}/`);
    if (pathArr[1] && validStepNames.includes(pathArr[1])) {
      return;
    }
  }

  if (
    currentOnboardStep &&
    (currentOnboardStep >= 1 || validStepNames.includes(currentOnboardStep))
  ) {
    history.push(`${path}/${currentOnboardStep}`);
  } else {
    history.push(`${path}/1`);
  }
};

const TechOnboardContainerA = ({ match, techProfile = {} }) => {
  const [onboardingData, setOnboardingData] = useState({});
  const [onboardingUpdates, setOnboardingUpdates] = useState({});
  const [isRegistrationDataParsed, setIsRegistrationDataParsed] =
    useState(false);
  const [onboardingOptions, setOnboardingOptions] = useState({});
  const [isUpdatingProfile, setIsUpdatingProfile] = useState(false);
  const [queryParams, setQueryParams] = useState(null);
  const [currentStep, setCurrentStep] = useState(null);
  const [skippedSteps, setSkippedSteps] = useState(liteProfileSkippedSteps);
  const priorStep = usePrevious(currentStep);
  const node = document.getElementById('registration-app');
  const { ...options } = JSON.parse(node.getAttribute('data'));
  // TODO: Support Lite Profile Activation?
  const isLiteProfileActivating = false;
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    setQueryParams(parsePageQuery());
    document.documentElement.classList.add('onboarding-ironman');
    // This fixes some odd scroll behavior on iOS when the onscreen keyboard is
    // hidden.
    document.addEventListener('focusout', () => {
      if (document.documentElement.scrollTop > 0) {
        document.documentElement.scrollTop = 0;
      }
    });
    setOnboardingOptions(augmentOptions(options, Object.keys(options)));
    if (!techProfile?.user_id) {
      if (typeof localforage !== 'undefined') {
        localforage.getItem(
          'onboardingUpdates',
          (err, localOnboardingUpdates) => {
            setUrl(
              localOnboardingUpdates?.current_onboard_step || 1,
              history,
              match.path,
              location?.pathname
            );
            setOnboardingUpdates({
              ...localOnboardingUpdates,
              ...onboardingUpdates,
            });
            setOnboardingData(
              getAugmentedOnboardingData(onboardingData, techProfile, options)
            );
            setIsRegistrationDataParsed(true);
          }
        );
      } else {
        setOnboardingData(
          getAugmentedOnboardingData(onboardingData, techProfile, options)
        );
        setUrl(1, history, match.path, location?.pathname);
        setIsRegistrationDataParsed(true);
      }
    } else {
      if (techProfile?.signup_intent === 'resume') {
        liteProfileSkippedSteps.push(6, 13, 14);
        setSkippedSteps(liteProfileSkippedSteps);
      }
      setOnboardingData(
        getAugmentedOnboardingData(onboardingData, techProfile, options)
      );
      setUrl(
        techProfile?.current_onboard_step || 1,
        history,
        match.path,
        location?.pathname
      );
      setIsRegistrationDataParsed(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!Object.keys(onboardingData).length) {
      return;
    }
    const step = location.pathname.replace(`${match.path}/`, '');
    if (validStepNames.includes(step)) {
      return;
    }
    let stepNumber = Number(step);
    if (Number.isNaN(stepNumber) || Number(stepNumber) === 0) {
      stepNumber = 1;
    }
    if (isLiteProfileActivating) {
      if (stepNumber === 1) {
        stepNumber = 2;
        setUrl(stepNumber, history, match.path, location?.pathname);
      } else if (liteProfileSkippedSteps.includes(stepNumber)) {
        if (stepNumber > currentStep) {
          stepNumber = Math.max(...liteProfileSkippedSteps) + 1;
        } else {
          stepNumber = Math.min(...liteProfileSkippedSteps) - 1;
        }
        setUrl(stepNumber, history, match.path, location?.pathname);
      }
    }
    if (stepNumber < PHONE_STEP && onboardingData.phone.text) {
      stepNumber = PHONE_STEP + 1;
    }

    if (
      !isLiteProfileActivating &&
      onboardingData &&
      onboardingData.user_id &&
      onboardingData.signup_intent !== 'resume' &&
      stepNumber < PASSWORD_STEP
    ) {
      stepNumber = PASSWORD_STEP;
    }
    if (stepNumber) {
      if (currentStep !== stepNumber) {
        setCurrentStep(stepNumber);
      }
    }
  }, [
    currentStep,
    history,
    location?.pathname,
    match.path,
    onboardingData,
    isLiteProfileActivating,
  ]);

  const homePath = '/tech/home';

  if (!isRegistrationDataParsed) {
    return null;
  }

  return (
    <OnboardingContext.Provider
      value={{
        history,
        isLiteProfileActivating,
        isUpdatingProfile,
        onboardingBasePath: match.path,
        onboardingData,
        onboardingOptions,
        onboardingUpdates,
        priorStep,
        profileType: 'tech',
        setIsUpdatingProfile,
        setOnboardingData,
        setOnboardingUpdates,
        skippedSteps,
        totalSteps: 13,
      }}
    >
      <BrowserRouter>
        <Switch>
          <Redirect exact from={match.url} to={`${match.path}/1`} />
          <Route
            path={`${match.path}/:stepNumber`}
            render={(props) => {
              const step = location.pathname.replace(`${match.path}/`, '');
              if (validStepNames.includes(step)) {
                switch (step) {
                  case 'learn':
                    return (
                      <OnboardingEducation
                        {...props}
                        nextAction={`${match.path}/1`}
                      />
                    );
                  case 'schedule':
                    return (
                      <ScheduleScreening {...props} nextAction="/talent/home" />
                    );
                  default: {
                    return null;
                  }
                }
              }

              switch (currentStep) {
                case 1:
                  return (
                    <OnboardingPermanentJob
                      {...props}
                      nextAction={`${match.path}/${currentStep + 1}`}
                      stepNumber={currentStep}
                    />
                  );
                case 2:
                  return (
                    <OnboardingTimeline
                      {...props}
                      previousAction={`${match.path}/${currentStep - 1}`}
                      nextAction={`${match.path}/${currentStep + 1}`}
                      stepNumber={currentStep}
                    />
                  );
                case 3:
                  return (
                    <OnboardingTechRoles {...props} stepNumber={currentStep} />
                  );
                case 4:
                  return (
                    <OnboardingShiftEmployment
                      {...props}
                      stepNumber={currentStep}
                    />
                  );
                case 5:
                  return <OnboardingName {...props} stepNumber={currentStep} />;
                case 6:
                  return (
                    <OnboardingEmail
                      {...props}
                      nextAction={`${match.path}/${PASSWORD_STEP}`}
                      stepNumber={currentStep}
                      urlQuery={queryParams}
                    />
                  );
                case PASSWORD_STEP:
                  return (
                    <OnboardingPassword
                      {...props}
                      stepNumber={currentStep}
                      previousAction="#"
                    />
                  );
                case PHONE_STEP:
                  return (
                    <OnboardingPhoneSimple
                      {...props}
                      stepNumber={currentStep}
                    />
                  );
                case 9:
                  return (
                    <OnboardingCurrentLocation
                      {...props}
                      stepNumber={currentStep}
                      nextAction={
                        onboardingData.openToRelocation
                          ? `${match.path}/${currentStep + 1}`
                          : `${match.path}/${currentStep + 2}`
                      }
                    />
                  );
                case 10:
                  return (
                    <OnboardingLocationPersonalized
                      {...props}
                      stepNumber={currentStep}
                    />
                  );
                case 11:
                  return (
                    <OnboardingTechCredentials
                      {...props}
                      nextAction={`${match.path}/${currentStep + 1}`}
                      previousAction={
                        onboardingData.openToRelocation
                          ? `${match.path}/${currentStep - 1}`
                          : `${match.path}/${currentStep - 2}`
                      }
                      stepNumber={currentStep}
                    />
                  );
                case 12:
                  return (
                    <OnboardingTechCertifications
                      {...props}
                      nextAction={`${match.path}/${currentStep + 1}`}
                      stepNumber={currentStep}
                    />
                  );
                case 13:
                  return (
                    <OnboardingResume
                      {...props}
                      nextAction={`${match.path}/${currentStep + 1}`}
                      stepNumber={currentStep}
                    />
                  );
                case 14:
                  window.location = homePath;
                  return null;
                default: {
                  return null;
                }
              }
            }}
          />
        </Switch>
      </BrowserRouter>
    </OnboardingContext.Provider>
  );
};

TechOnboardContainerA.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    params: PropTypes.shape({
      stepNumber: PropTypes.string,
    }),
  }).isRequired,
  techProfile: PropTypes.object,
};

const mapStateToProps = (state) => ({
  techProfile: getTechProfile(state),
});

export default connect(mapStateToProps)(TechOnboardContainerA);
