import { createAction } from 'redux-actions';

import { doGet, doPatch } from 'shared/helpers/http';

import { displayErrorDialog } from './errorActions';

export const updateTalentProfileStarted = createAction(
  'UPDATE_TALENT_PROFILE_STARTED'
);

export const updateTalentProfileComplete = createAction(
  'UPDATE_TALENT_PROFILE_COMPLETE'
);

export const updateTalentProfileError = createAction(
  'UPDATE_TALENT_PROFILE_ERROR'
);

export const serverErrorsReceived = createAction(
  'TALENT_PROFILE_SERVER_ERRORS_RECEIVED'
);

export const getTalentProfileStarted = createAction(
  'GET_TALENT_PROFILE_STARTED'
);

export const getTalentProfileComplete = createAction(
  'GET_TALENT_PROFILE_COMPLETE'
);

export const getTalentProfileError = createAction('GET_TALENT_PROFILE_ERROR');

export function updateTalentProfile(tpId, tp, afterUpdateAction) {
  return (dispatch) => {
    dispatch(updateTalentProfileStarted());

    return doPatch(`/nurse_profile/${tpId}`, tp)
      .then((json) => {
        dispatch(updateTalentProfileComplete(json));
        dispatch(serverErrorsReceived({}));
        afterUpdateAction();
      })
      .catch((error) => {
        dispatch(updateTalentProfileError());
        if (error.response && error.response.status === 422) {
          error.response
            .json()
            .then((json) => dispatch(serverErrorsReceived(json)));
        } else {
          console.error(error);
          const errorMsg =
            'Oops, there was a problem connecting to the ' +
            'server. Please check your internet connection and refresh the ' +
            'page.';
          dispatch(displayErrorDialog(errorMsg));
        }
      });
  };
}

export function getTalentProfile(id) {
  return (dispatch) => {
    dispatch(getTalentProfileStarted());

    return doGet(`/nurse_profile/${id}`)
      .then((json) => {
        dispatch(getTalentProfileComplete(json.talent_profile));
        dispatch(serverErrorsReceived({}));
        return json.talent_profile;
      })
      .catch((error) => {
        dispatch(getTalentProfileError());
        if (error.response && error.response.status === 422) {
          error.response
            .json()
            .then((json) => dispatch(serverErrorsReceived(json)));
        } else {
          console.error(error);
          const errorMsg =
            'Oops, there was a problem connecting to the ' +
            'server. Please check your internet connection and refresh the ' +
            'page.';
          dispatch(displayErrorDialog(errorMsg));
        }
      });
  };
}
