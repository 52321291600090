import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { styled } from 'shared/components/ihcl/styled';

import { Checkbox } from 'shared/components/ihcl/checkbox';
import { Input } from 'shared/components/ihcl/input';
import { Center } from 'shared/components/ihcl/positioning';
import { MonthPicker } from 'shared/components/ihcl/month_picker_split_fields';
import { Select } from 'shared/components/ihcl/select';

import {
  Onboarding,
  OnboardingSubtitle,
  OnboardingTitle,
} from 'registration/containers/Onboarding';
import { educationLevelsForTechs as originalEducationLevelsForTechs } from 'shared/constants/selectOptions';
import { OnboardingContext } from '../../containers/Onboarding';

const educationLevelsForTechs = originalEducationLevelsForTechs.map(
  (option) => ({
    id: option.value,
    label: option.label,
  })
);

const InputWrapper = styled('div', ({ $theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: $theme.sizing.unit16,
}));

// eslint-disable-next-line react/require-default-props
const OnboardingResume = ({
  nextAction = null,
  onboardingWrapper = null,
  onboardingWrapperProps = {},
  previousAction = null,
  stepNumber,
}) => {
  const [hasAnotherDegree, setHasAnotherDegree] = useState(false);
  const {
    // @ts-ignore
    onboardingData,
    // @ts-ignore
    setOnboardingData,
  } = useContext(OnboardingContext);

  if (!onboardingData || Object.keys(onboardingData).length < 1) {
    return null;
  }

  return (
    <Onboarding
      currentStep={stepNumber}
      isNextDisabled={false}
      nextAction={nextAction}
      previousAction={previousAction}
      stepKeys={['workExperiences', 'workExperienceMethod']}
      onboardingWrapper={onboardingWrapper}
      onboardingWrapperProps={onboardingWrapperProps}
      validateBeforeSubmit={() => true}
    >
      <Center>
        <OnboardingTitle $reducedFontSize $additionalTopSpacing>
          Show off your credentials!
        </OnboardingTitle>
        <OnboardingSubtitle>
          Where did you complete your certification program?
        </OnboardingSubtitle>
        <InputWrapper>
          <Input
            value={onboardingData.talent_education_experiences?.name}
            onChange={(e) =>
              setOnboardingData({
                ...onboardingData,
                talent_education_experiences: {
                  ...onboardingData.talent_education_experiences,
                  name: e.target.value,
                },
              })
            }
            label="Institution attended"
          />
          <Input
            value={onboardingData.talent_education_experiences?.field}
            onChange={(e) =>
              setOnboardingData({
                ...onboardingData,
                talent_education_experiences: {
                  ...onboardingData.talent_education_experiences,
                  field: e.target.value,
                },
              })
            }
            label="Field of study"
          />
          <MonthPicker
            instanceId="graduationDate"
            yearLabel="Graduation year"
            yearValue={onboardingData.talent_education_experiences?.year}
            monthValue={onboardingData.talent_education_experiences?.month}
            onChange={({ month, year }) => {
              setOnboardingData({
                ...onboardingData,
                talent_education_experiences: {
                  ...onboardingData.talent_education_experiences,
                  month,
                  year,
                },
              });
            }}
          />
          <Checkbox
            checked={hasAnotherDegree}
            onChange={(e) => setHasAnotherDegree(e.target.checked)}
          >
            I have another degree
          </Checkbox>
        </InputWrapper>
        {hasAnotherDegree && (
          <>
            <OnboardingSubtitle>
              Where did you get your degree?
            </OnboardingSubtitle>
            <InputWrapper>
              <Select
                value={educationLevelsForTechs.filter(
                  (levelOption) =>
                    levelOption.id ===
                    onboardingData.talent_education_experiences?.degree?.level
                )}
                onChange={(e) => {
                  setOnboardingData({
                    ...onboardingData,
                    talent_education_experiences: {
                      ...onboardingData.talent_education_experiences,
                      degree: {
                        ...onboardingData.talent_education_experiences?.degree,
                        level: e.option?.id,
                      },
                    },
                  });
                }}
                options={educationLevelsForTechs}
                placeholder="Please select a level"
              />
              <Input
                value={
                  onboardingData.talent_education_experiences?.degree?.name
                }
                onChange={(e) =>
                  setOnboardingData({
                    ...onboardingData,
                    talent_education_experiences: {
                      ...onboardingData.talent_education_experiences,
                      degree: {
                        ...onboardingData.talent_education_experiences?.degree,
                        name: e.target.value,
                      },
                    },
                  })
                }
                label="Educational institution attended"
              />
              <Input
                value={
                  onboardingData.talent_education_experiences?.degree?.field
                }
                onChange={(e) =>
                  setOnboardingData({
                    ...onboardingData,
                    talent_education_experiences: {
                      ...onboardingData.talent_education_experiences,
                      degree: {
                        ...onboardingData.talent_education_experiences?.degree,
                        field: e.target.value,
                      },
                    },
                  })
                }
                label="Field of study/major"
              />
              <MonthPicker
                yearLabel="Graduation year"
                yearValue={onboardingData.talent_education_experiences?.year}
                monthValue={onboardingData.talent_education_experiences?.month}
                onChange={({ month, year }) => {
                  setOnboardingData({
                    ...onboardingData,
                    talent_education_experiences: {
                      ...onboardingData.talent_education_experiences,
                      degree: {
                        ...onboardingData.talent_education_experiences?.degree,
                        month,
                        year,
                      },
                    },
                  });
                }}
              />
            </InputWrapper>
          </>
        )}
      </Center>
    </Onboarding>
  );
};
OnboardingResume.propTypes = {
  nextAction: PropTypes.string,
  onboardingWrapper: PropTypes.func,
  onboardingWrapperProps: PropTypes.object,
  previousAction: PropTypes.string,
  stepNumber: PropTypes.number.isRequired,
};

export default OnboardingResume;
