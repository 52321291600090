import React, { useContext, useRef } from 'react';
import PropTypes from 'prop-types';

import { Center } from 'shared/components/ihcl/positioning';
import { Input } from 'shared/components/ihcl/input';

import {
  Onboarding,
  OnboardingContext,
  OnboardingTitle,
  OnboardingSubtitle,
} from 'registration/containers/Onboarding';

// eslint-disable-next-line react/require-default-props
const OnboardingPassword = ({
  nextAction = null,
  previousAction = null,
  stepNumber,
}) => {
  const { onboardingData, setOnboardingData } = useContext(OnboardingContext);
  const nextActionButtonRef = useRef(null);
  if (!onboardingData || Object.keys(onboardingData).length < 1) {
    return null;
  }
  const { errors, password } = onboardingData;

  let passwordError = null;
  if (errors && errors.password && Array.isArray(errors.password)) {
    passwordError = errors.password.join('; ');
  }

  const setPassword = (passwordStr, errorMessage) => {
    let newErrors = null;
    if (errorMessage) {
      newErrors = {
        ...errors,
        password: errorMessage,
      };
    } else if (errors) {
      newErrors = Object.fromEntries(
        Object.entries(errors).filter(([key]) => key !== 'password')
      );
    }
    const newOnboardingData = {
      ...onboardingData,
      password: passwordStr,
    };
    if (newErrors) {
      newOnboardingData.errors = newErrors;
    }
    setOnboardingData(newOnboardingData);
  };

  return (
    <Onboarding
      currentStep={stepNumber}
      isNextDisabled={!password}
      nextAction={nextAction}
      nextActionButtonRef={nextActionButtonRef}
      previousAction={previousAction}
      stepKeys={['password']}
    >
      <Center>
        <OnboardingTitle>Create your password</OnboardingTitle>
        <Center>
          <OnboardingSubtitle>
            We care about your career -- but first, we care about your security
          </OnboardingSubtitle>
          <form
            action="#"
            onSubmit={(evt) => {
              evt.preventDefault();
              if (
                !passwordError &&
                nextActionButtonRef &&
                nextActionButtonRef.current
              ) {
                nextActionButtonRef.current.click();
              }
            }}
          >
            <Input
              value={password}
              type="password"
              label="Type your password"
              onChange={(evt) => {
                setPassword(evt.target.value, null);
              }}
              onBlur={(evt) => {
                if (!evt.target.value) {
                  setPassword(evt.target.value, 'Please enter a password');
                }
              }}
              error={!!passwordError}
              caption={passwordError}
            />
          </form>
        </Center>
      </Center>
    </Onboarding>
  );
};
OnboardingPassword.propTypes = {
  nextAction: PropTypes.string,
  previousAction: PropTypes.string,
  stepNumber: PropTypes.number.isRequired,
};

export default OnboardingPassword;
