import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { OnboardingTitle } from 'registration/components/new_pages/OnboardingCurrentLocation/OnboardingTitle';
import { OtherLocations } from 'registration/components/new_pages/OnboardingCurrentLocation/OtherLocations';
import { PediatricLocations } from 'registration/components/new_pages/OnboardingCurrentLocation/PediatricLocations';
import { TagLocationPinIcon } from 'registration/containers/Onboarding';
import normalizeLocation from 'registration/helpers/normalizeLocation';
import { Input } from 'shared/components/ihcl/input';
import { Center } from 'shared/components/ihcl/positioning';
import { Spinner } from 'shared/components/ihcl/spinner';
import { SIZE as TAG_SIZE, Tag } from 'shared/components/ihcl/tag';
import { doPost } from 'shared/helpers/http';
import { getLocationFromCityObj } from 'shared/helpers/parsers';

const pediatricSpecialtyIds =
  process.env.NODE_ENV === 'development'
    ? [49, 50, 51, 52]
    : [29, 50, 101, 102];

const zipCodeErrMsg =
  'We encountered an error while looking up your' +
  'zipcode. Please check your internet connection, refresh ' +
  'your browser and try again.';
const invalidZipCodeErrorMsg = 'Please enter a valid zipcode';

const getPediatricLocations = (onboardingInformation) =>
  doPost('/talent_onboard/pediatric_job_openings', onboardingInformation)
    .then((resp) => {
      if (resp?.length) {
        return resp.map(normalizeLocation);
      }
      return [];
    })
    .catch(() => []);

// eslint-disable-next-line react/require-default-props
export const AddLocation = ({
  // eslint-disable-next-line react/require-default-props
  currentLocation,
  setCurrentLocation,
  // eslint-disable-next-line react/require-default-props
  openToRelocation,
  setOpenToRelocation,
  // eslint-disable-next-line react/require-default-props
  isRecentGrad,
  // eslint-disable-next-line react/require-default-props
  onboardingInformation,
  otherDesiredLocations,
  setOtherDesiredLocations,
  // eslint-disable-next-line react/require-default-props
  shouldPreselectOtherLocations,
  // eslint-disable-next-line react/require-default-props
  variant,
}) => {
  const currentLocationZipCode =
    currentLocation && (currentLocation.zipCode || currentLocation.zip_code);
  const [zipCode, setZipCode] = useState(currentLocationZipCode || '');
  const [zipCodeError, setZipCodeError] = useState(null);
  const [fetchingLocation, setFetchingLocation] = useState(false);
  const [pediatricLocations, setPediatricLocations] = useState([]);
  const zipCodeRef = useRef(null);
  const hasPediatricSpecialties = onboardingInformation?.nurseSpecialties?.some(
    (nsId) => pediatricSpecialtyIds.includes(nsId)
  );

  useEffect(() => {
    if (hasPediatricSpecialties) {
      getPediatricLocations(onboardingInformation).then((resp) => {
        setPediatricLocations(resp.map(normalizeLocation));
      });
    }
  }, []);

  useEffect(() => {
    if (currentLocation?.id && shouldPreselectOtherLocations) {
      setOpenToRelocation(true);
    }
  }, [currentLocation, setOpenToRelocation, shouldPreselectOtherLocations]);

  const handleInputChange = (evt) => {
    const {
      value,
      validity: { patternMismatch },
    } = evt.target;
    setZipCode(value);
    setZipCodeError(null);
    if (value && value.length >= 5) {
      if (patternMismatch) {
        setZipCodeError(invalidZipCodeErrorMsg);
        return;
      }

      setFetchingLocation(true);
      fetch(`/zip_codes/${value}`)
        .then((response) => {
          if (response.status === 404) {
            throw new Error('Not Found');
          }
          return response.json();
        })
        .then((json) => {
          setCurrentLocation(getLocationFromCityObj(json));
          setFetchingLocation(false);
          if (zipCodeRef.current) {
            zipCodeRef.current.blur();
          }
        })
        .catch((error) => {
          setFetchingLocation(false);

          if (error.message === 'Not Found') {
            setZipCodeError(invalidZipCodeErrorMsg);
          } else {
            setZipCodeError(zipCodeErrMsg);
          }
        });
    } else if (currentLocation) {
      setCurrentLocation(null);
    }
  };

  const validLocation = currentLocationZipCode === zipCode.trim();
  const tagContents = currentLocation ? (
    <div>
      <TagLocationPinIcon />
      {currentLocation.city}, {currentLocation.state}
    </div>
  ) : (
    ''
  );

  return (
    <Center>
      <OnboardingTitle>What&apos;s your current location?</OnboardingTitle>
      <Center>
        <Input
          caption={zipCodeError}
          inputMode="decimal"
          value={zipCode}
          label="Zipcode"
          onChange={handleInputChange}
          pattern="\s*\d{5}\s*"
          endEnhancer={fetchingLocation && <Spinner />}
          positive={validLocation}
          error={!!zipCodeError}
          ref={zipCodeRef}
        />
        <div
          style={{
            display: validLocation ? 'block' : 'none',
            marginTop: '16px',
          }}
        >
          <Tag $fadeIn $fullWidth closeable={false} size={TAG_SIZE.medium}>
            {tagContents}
          </Tag>
          {!shouldPreselectOtherLocations && (
            <>
              <br />
              {hasPediatricSpecialties && variant === 'teefs' ? (
                <PediatricLocations
                  pediatricLocations={pediatricLocations}
                  openToRelocation={openToRelocation}
                  setOpenToRelocation={setOpenToRelocation}
                  otherDesiredLocations={otherDesiredLocations}
                  setOtherDesiredLocations={setOtherDesiredLocations}
                />
              ) : (
                <OtherLocations
                  openToRelocation={openToRelocation}
                  setOpenToRelocation={setOpenToRelocation}
                  isRecentGrad={isRecentGrad}
                />
              )}
            </>
          )}
        </div>
      </Center>
    </Center>
  );
};

AddLocation.defaultProps = {
  currentLocation: null,
  isRecentGrad: false,
  onboardingInformation: null,
  openToRelocation: null,
  shouldPreselectOtherLocations: false,
  variant: null,
};

AddLocation.propTypes = {
  currentLocation: PropTypes.shape({
    city: PropTypes.string,
    id: PropTypes.string,
    state: PropTypes.string,
    zipCode: PropTypes.string,
    zip_code: PropTypes.string,
  }),
  isRecentGrad: PropTypes.bool,
  onboardingInformation: PropTypes.shape({
    nurseSpecialties: PropTypes.arrayOf(PropTypes.number),
  }),
  openToRelocation: PropTypes.bool,
  otherDesiredLocations: PropTypes.array.isRequired,
  setCurrentLocation: PropTypes.func.isRequired,
  setOpenToRelocation: PropTypes.func.isRequired,
  setOtherDesiredLocations: PropTypes.func.isRequired,
  shouldPreselectOtherLocations: PropTypes.bool,
  variant: PropTypes.string,
};
